import React from 'react'
import HeaderFive from '../../component/header/HeaderFive';
import ArticleCard from '../components/ArticleCard';
import "../css/text.css"

const Background ="/assets/aveImages/mainImage/articles.png";

const articles1 = [
    {
      buttonLink:"selectingUni",
      image: "/assets/aveImages/articles/selectingBestUni.jpg",
      title: "Selecting the best University",
      description:"Blog",
    },
    {
      buttonLink:"fiveMistakes",
      image: "/assets/aveImages/articles/5mistakes.jpg",
      title: "Top 5 Mistakes in Application Proces to Study in Canada",
      description:"Blog",
    },
    {
      buttonLink:"mustDos",
      image: "/assets/aveImages/articles/mustDos.jpg",
      title: "Some must-dos before choosing a study abroad destination",
      description:"Blog",
    },
  ];
  const articles2 = [
    {
      buttonLink:"applyMaster",
      image: "/assets/aveImages/articles/applyMasterAustralia.jpg",
      title: "How to Apply for a Master's Degree in Australia?",
      description:"Blog",
    },
    {
      buttonLink:"masterCourses",
      image: "/assets/aveImages/articles/masterCourses.jpg",
      title: "Top 10 Master's Courses for International Students",
      description:"Blog",
    },
  ];

export default function Articles() {
    return (
        <div>
           <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
           {/* Start Breadcrump Area */}
           <div className="rn-page-title-area pt--120 pb--110 bg_image"  data-black-overlay="1"
            style={{backgroundImage: `url(${Background})`,}}
           >
                    <div className="container" >
                            <div className="col-lg-6 pt--110 " >
                                <div className="rn-page-title pt--110" >
                                    <h2 className="title " style={{color:"white"}}>ARTICLES</h2>
                                </div>
                                </div>
                            </div>
                            </div>
                
                {/* End Breadcrump Area */}

                             {/* Start Single article  */}
            <div className="col-lg-12 container">
              <div className="row pb--110">
                {articles1.map((value, index) => (
                  <ArticleCard 
                  key={index}
                image={value.image}
                  title={value.title}
                  description={value.description}
                buttonLink={value.buttonLink}
                  />
                ))}
              </div>
            </div>
            {/* End Single Service  */}

            {/* Start Single article  */}
            <div className="col-lg-12 container">
              <div className="row pb--110">
                {articles2.map((value, index) => (
                  <ArticleCard 
                  key={index}
                image={value.image}
                  title={value.title}
                  description={value.description}
                buttonLink={value.buttonLink}
                  />
                ))}
              </div>
            </div>
            {/* End Single Service  */}



                   
        </div>
    )
}

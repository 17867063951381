import React, { Component } from 'react';
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";

const SlideList = [
    {
      bgImage: "slider--2",  
    },
    {
      bgImage: "slider--3",
     
    },
    {
      bgImage: "slider--1", 
    },
  ];

export default class AveSlider extends Component {
    render() {
        return (
            <div>
                
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                />
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}
                
            </div>
        )
    }
}


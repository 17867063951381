import React, { Component } from "react";
import emailjs from 'emailjs-com';
import axios from 'axios';

const textColor={
    color:" #3F47CC",   
}
const description={
    fontSize:"14px",
}

class ContactTwo extends Component{

    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnResidence:'',
            rnMessage: '',
            rnSending:false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailToOwner = this.handleEmailToOwner.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.rnName!==''&& this.state.rnEmail!==''&& this.state.rnSubject!==''&& this.state.rnResidence!==''&& this.state.rnMessage!==''){
            emailjs.sendForm('service_hw0s44c', 'template_e8vin7h', e.target, 'user_Ts8qxUgrR0BYXZCSD8ozq')
            .then((result) => {
                alert(result.text);
            }, (error) => {
                alert(error.text);
            }); 
            e.reset();
        }else{
            alert("Please fill the entire form.");
        }
      };

       handleEmailToOwner(e){
           var _this=this;
        _this.setState({rnSending: true});
           e.preventDefault();
           if(this.state.rnName!==''&& this.state.rnEmail!==''&& this.state.rnSubject!==''&& this.state.rnResidence!==''&& this.state.rnMessage!==''){
            let emailParams = {
                'name': this.state.rnName,
                'email': this.state.rnEmail,
                'residence': this.state.rnResidence,
                'subject': this.state.rnSubject,
                'message': this.state.rnMessage
            }
            axios.post( '/sendingmail.php', emailParams)
            .then(function(response){
                console.log(response);
                if( response.data.status ) {
                    _this.setState({rnSending:false});
                    alert("Sent");
                } else {
                    _this.setState({rnSending:false});
                    alert("Error");
                   
                }
            }).then( () => {
             _this.setState({rnName:''});
            _this.setState({rnEmail:''});
            _this.setState({rnResidence:''});
            _this.setState({rnSubject:''});
            _this.setState({rnMessage:''});})
        
           
        }else{
            alert("Please fill the entire form.");
        }
      
    }

    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                    <div className="col-lg-6 order-2 order-lg-1">
                        {this.props.screen==="home"? <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/aveImages/contactForm/contactForm.png" alt="avenue"/>
                            </div>
                            :<div style={{textAlign:"justify"}}>
                                <p style={description}>Since our inception in 2006, we have served as a bridge connecting Nepalese students with education providers all over the world. We were founded with the primary goal of providing high-quality educational services to Nepalese graduates who wish to study abroad. We have a more than a decade of experience serving a wide range of students, universities/colleges, and educational programs, delivering high-quality results to the general public. Primarily, we stand for conviction, dependability, and unwavering commitment to the aspirations of students who wish to study abroad, and we have been successful in showing our students the appropriate educational destination up to this point. Our improved services, beneficial guidance, and friendly behavior toward our fellow students have made us proud, and we have always been praised and appreciated throughout our professional journey.</p>
                                <p style={description}><span style={textColor}>Avenue Educational Consultancy</span> is an authorized representative of a number of institutions from various countries around the world, including the United States, the United Kingdom, Australia, Canada, New Zealand, and Europe. As a result, we assist our students in selecting the best educational institute for their academic needs.</p>    
                                <p style={description}>During the process of becoming affiliated with Grace International and processing further, we offer a variety of services that make the admissions process easier for our students. Some of the services we've been providing include:</p>
                                <ul>
                                    <li><p style={description}>Professional English proficiency test preparation classes and tests to receive an assessment prior to taking the actual test. </p></li>
                                    <li><p style={description}>Genuine information about the world's most prestigious colleges and universities.</p></li>
                                    <li><p style={description}>Providing the best advice in order to select the appropriate course for students' academic backgrounds.</p></li>
                                    <li><p style={description}>Our students receive study advice as well as information on how to apply for scholarships.</p></li>
                                </ul>
                                <p style={textColor}>Preparation for the test</p>
                                <p style={description}>Language proficiency tests are one of the most important aspects of studying abroad. As a result, we provide classroom-based training for <span style={textColor}>IELTS, TOEFL, PTE, SAT, GRE, and GMAT</span> in order to prepare students to achieve the required score. We offer comprehensive and results-oriented coaching with a focus on individual attention and scheduling flexibility. Some of our proudest achievements include well-researched material, excellent instruction, a massive book collection at our academic library, a variety of mock tests, and a professional instructor. Many of our students have improved their test scores as a result of better instruction provided by our professional instructor. This is why, in addition to Kathmandu, we are well-known in other Nepalese cities. Thousands of students enroll with us in order to better prepare for their tests and achieve a higher score.</p>
                                <p style={description}>One final piece of advice: </p>
                                <p style={description}>Don't believe everything you read on the internet. Some student reviews may be skewed by a single negative personal experience or the decision to take a course that wasn't right for them. It does not always imply that you will have the same reaction. Grace will make certain that you receive accurate and unbiased advice. To begin your search, go to this website's course search.</p>
                            </div>
                        }
                           
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="section-title text-left mb--50">
                                <h2 className="title" style={{color:"#3f47cc"}}>{this.props.title}</h2>
                        
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={(e)=>this.handleEmailToOwner(e)} >
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Full Name *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Email *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Your Question is Regarding *"
                                        />
                                    </label>
                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="residence"
                                            id="item03"
                                            value={this.state.rnResidence}
                                            onChange={(e)=>{this.setState({rnResidence: e.target.value});}}
                                            placeholder="Your City of Residence *"
                                        />
                                    </label>
                                   
                                    <label htmlFor="item05">
                                        <textarea
                                            type="text"
                                            id="item05"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Write your enquriy here. *"
                                        />
                                    </label>
                                    {this.state.rnSending?
                                     <button className="rn-button-style--2 btn-solid" disabled type="submit" value="submit" name="submit" id="mc-embedded-subscribe">
                                     Sending </button>
                                     :  <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">
                                     Submit </button>}
                                  
                                </form>
                            </div>
                        </div>       
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;
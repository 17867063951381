import React from 'react'
import HeaderFive from '../../../component/header/HeaderFive';
import ApplyNow from '../../components/ApplyNow';
import LeftTextDescription from '../../components/LeftTextDescription'
import RightTextDescription from '../../components/RightTextDescription'
import "../../css/text.css";
const Background ="/assets/aveImages/mainImage/ielts.png";

const intro=<div>
    <h3 className="headingStyle">What exactly is it? </h3>
<p>The International English Language Testing System (IELTS) is the most widely used English language test in the world. Over 25 years ago, IELTS was one of the first four-skill English language tests, and it continues to set the standard for English language testing today. Over 9,000 organizations around the world accept IELTS as proof of English language proficiency. More than 2.2 million tests were taken worldwide last year. For education, immigration, and professional accreditation, IELTS is recognized as a secure, valid, and reliable indicator of true-to-life English communication ability. British Council, IDP: IELTS Australia, and Cambridge English Language Assessment jointly own and operate IELTS, which has over 1,000 test centers and locations in over 140 countries.</p>
</div>

const intended=<div>
    <h3 className="headingStyle">Who is it intended for? </h3>
<p>Universities and employers in many countries, including Australia, Canada, Ireland, New Zealand, the United Kingdom, and the United States, accept IELTS. Professional bodies, immigration authorities, and other government agencies all recognize it. The test is taken by over 1.4 million people each year.
    </p>
 <h4 className="headingStyle">What does the IELTS test entail?</h4>
<p>You can take the test in either Academic or General Training mode. The Listening and Speaking sections are the same for all candidates.
Scores and level: Multi-level. You are given a score ranging from 1 to 9. Half-point scores of 6.5 are possible. An IELTS score of 6 or 7 is frequently required by universities. They might also set a minimum score requirement for each of the four sections.
</p>
</div>

const test=<div>
    <h3 className="headingStyle">Where can we take the test? </h3>
    <p>
IELTS tests are given at accredited Test Centres all over the world; there are currently over 500 Test Centres in over 120 countries.
</p>
<h4 className="headingStyle">When will we be able to take the test?</h4>
<p>
Make an appointment with the nearest test center. Dates are scheduled on a regular basis, usually on Saturdays.
</p>
<h4 className="headingStyle">What is the cost of taking IELTS?</h4>
<p>
Fees are determined by test centers and vary by country. In Nepal, the IELTS exam costs NPR 20,500.
</p>
</div>

export default function Ielts() {
    return (
        <div>
             <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--110 bg_image"  data-black-overlay="1"
         style={{backgroundImage: `url(${Background})`,}}
        >
           <div className="container" >
                   <div className="col-lg-6 pt--110" >
                       <div className=" rn-page-title pt--100" >
                           <h2 className="title " style={{color:"white"}}>IELTS</h2>
                       </div>
                   </div>
           </div>
       </div>
       {/* End Breadcrump Area */}
       <div className="ptb--120">
       <RightTextDescription description={intro} image="/assets/aveImages/ielts/ielts1.png"/>
       <div className="pb--110">
       <LeftTextDescription description={intended} image="/assets/aveImages/ielts/ielts2.png"/>
       </div>
       <RightTextDescription description={test} image="/assets/aveImages/ielts/ielts3.png"/>
         <ApplyNow/>
    
       </div>
   </div>
    )
}


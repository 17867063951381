import React, { Component } from 'react'
import "../../css/text.css"

const Scholarship1=<p><span className="headingStyle">Destination-specific</span><br/>
These scholarships are offered by a country to students who are interested in participating in study abroad programs in that country.<br/><br/>
<span className="headingStyle">Program-specific</span><br/>
Individual study abroad programs provide these, which are often associated with qualified applicants based on academic and personal achievement.
</p>

const Scholarship2 =<p><span className="headingStyle">Merit-based</span><br/>
These are based on the qualifications and certificates of the students.<br/><br/>
<span className="headingStyle">Student-specific</span><br/>
These are determined by factors such as gender, race, family, religion, and a variety of other student-specific characteristics.</p>

const Scholarship3=<p><span className="headingStyle">Subject-specific</span><br/>
Study abroad programs award these scholarships to students based on their chosen field of study.</p>
export default class ScholarshipType extends Component {
    render() {
        return (
            <div>
                <div className="rn-about-area pt--110">
                    <div className="container">
                        <h3 className="section-title">Scholarships Types</h3>
                        <div className="row pb--110 textAlign">
                            <div className="col-lg-4">
                            {Scholarship1}
                            </div>
                            <div className="col-lg-4">
                            {Scholarship2}
                            </div>
                            <div className="col-lg-4">
                            {Scholarship3}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

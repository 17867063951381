import React, { Component } from "react";
import HeaderFive from "../../../component/header/HeaderFive";
import Contact from "../../../elements/contact/ContactTwo";

const Background = "/assets/aveImages/mainImage/study1.png";

export default class StudyAbroadWithAvenue extends Component {
  render() {
    return (
      <div>
         <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--110 bg_image"
          data-black-overlay="1"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="container">
            <div className="col-lg-6 pt--100">
              <div className="rn-page-title pt--100">
                <h2 className="title" style={{ color: "white" }}>
                  Study abroad with Avenue
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {/* Start Contact Us */}
        <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
          <Contact title="Do you want to study abroad with Avenue?" /> 
        </div>
        {/* End Contact Us */}
      </div>
    );
  }
}

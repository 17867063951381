import React from 'react'
import HeaderFive from '../../../component/header/HeaderFive';
import ApplyNow from '../../components/ApplyNow';
import LeftTextDescription from '../../components/LeftTextDescription'
import RightTextDescription from '../../components/RightTextDescription'
import "../../css/text.css";
const Background ="/assets/aveImages/mainImage/pte.png";

const intro=<div>
  <h3 className="headingStyle">What exactly is it?</h3>
<p>The Pearson Test of English Academic is a language assessment tool used to determine whether non-native English speakers are ready to enroll in a university-level English language program. Pearson developed PTE Academic in response to requests from educational institutions, government agencies, and other organizations for a more accurate way of assessing non-native English language students entering the English-speaking academic world.</p>
</div>

const intended=<div>
  <h3 className="headingStyle">Who is it intended for?</h3>
<p>PTE Academic is a computer-based academic English language test for non-native English speakers who want to study in a foreign country. It assesses your ability to read, write, listen, and speak. Questions that combine two skills, such as listening and reading or reading and speaking, are common.</p>
</div>

const fastResult=<div>
  <h3 className='headingStyle'>Fast Result</h3>
  <p> Most test takers get their results within 48 hours. Plus, you can choose from frequent test dates and book last-minute.</p>
<p>PTE Academic measures your English speaking, listening, reading, and writing skills in a single, short test.</p>
</div>

export default function Pte() {
    return (
        <div>
             <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--110 bg_image"  data-black-overlay="1"
         style={{backgroundImage: `url(${Background})`,}}
        >
           <div className="container" >
           <div className="col-lg-6 pt--110 " >
                       <div className="rn-page-title pt--100" >
                           <h2 className="title " style={{color:"white"}}>PTE</h2>
                       </div>
                   </div>
           </div>
       </div>
       {/* End Breadcrump Area */}
       <div className="ptb--120">
       <RightTextDescription description={intro} image="/assets/aveImages/pte/pte1.png"/>
       <div className="pb--110">
       <LeftTextDescription description={intended} image="/assets/aveImages/pte/pte2.png"/>
       </div>
       <RightTextDescription description={fastResult} image="/assets/aveImages/pte/pte3.png"/>
         <ApplyNow/>
       </div>
   </div>
    )
}

import React, { Component } from 'react'
import "../../css/text.css"

const weProvide=<div><p>We offer a variety of services, including the following:</p>
<ul>
    <li>Look for scholarships that are tailored to the student's financial situation and academic interests.</li>
    <li>Notify students about new scholarships that are coming up.</li>
    <li>Provide information on international scholarship opportunities.</li>
    <li>Assist with the preparation of required documents and the application process.</li>
</ul>
</div>;

const guidelines=<p>Attending college in a foreign country may seem daunting due to the high cost of education, but that is precisely why you must seek out, apply for, and be awarded scholarships in order to avoid being saddled with large student loan payments after graduation. <br/><br/>
Scholarships are no longer considered an afterthought. They're necessary to bridge the gap between the rising cost of education abroad and your financial means. As a result, we're confident that you understand why scholarships have become so important.</p>

export default class WeProvide extends Component {
    render() {
        return (
            <div>
                <div className="rn-about-area">
                    <div className="container">
                        <div className="row pb--110 textAlign">
                            <div className="col-lg-6">
                            <h3 className="section-title">We Provide</h3>
                            {weProvide}
                            </div>
                            <div className="col-lg-6">
                            <h3 className="section-title">Guidance and Advice</h3>
                            {guidelines}
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        )
    }
}

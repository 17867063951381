import React, { Component } from "react";
import {
  FaLinkedinIn,
  FaFacebookF,
} from "react-icons/fa";
import {
  FiMail,  
  FiPhone,
  FiClock,
  FiMapPin,
} from "react-icons/fi";
import "../css/text.css"

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/Avenue-Education-Consultancy-Kathmandu-100483987991359" },
  { Social: <FaLinkedinIn/>, link: "https://www.linkedin.com/authwall?trk=gf&trkInfo=AQH-MiRUt5tDbwAAAX2Ba-popTVsX2xfSITSmXoj1zgmntk8GA13z8KxMyXjnm0TQQZN1aHMGwYRwxgVps1tnD7OKcC6YZKPPq4mlFEuaYuZXkwo6LGZlqxfITBEaXU9rxgQsq4=&originalReferer=https://l.facebook.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F65757686%2Fadmin%2F%3Ffbclid%3DIwAR0jnpLgCMUX7WOtm7nyu0xTgpUBzUr8ofinXKtAQ-c-_2iD9HVwU__vfNU" },
];

const linkList = {
  listStyle: "none",
  marginLeft: "0px",
  paddingLeft: "0px",
};

const footerText ={
  color: "#fafafa",
    fontSize: "16px",
    opacity: "0.75",
}
const underlineText={textDecoration:"underline",textDecorationColor:"#fff", textUnderlinePosition:"under" }



export default class AveFooter extends Component {
  render() {
    return (
      <div
        className="footer-style-2 ptb--30 footerStyle"
      >
        <div className="wrapper plr--50 plr_sm--20">
          <div className="row  justify-content-between">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="inner">
                <div className="logo text-center text-sm-left mb_sm--20">
        
                  <a href="/">
                    <img src="/assets/aveImages/companyLogo/avenueLogo.png" width="100" height="100" alt="Logo images" />
                  </a>
                
                  <p style={footerText}>
                    AEC will serve as a bridge for prospective students who are
                    in quest of a more challenging educational ladder by
                    endeavoring a higher discipline to different colleges and/or
                    universities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt_mobile--30">
              <div className="footer-link">
                <h4 style={underlineText}>QUICK LINKS</h4>
   
                <ul className="ft-link" style={linkList}>
                  <li>
                    <a href="/aboutUs">| About Us</a>
                  </li>
                  <li>
                    <a href="/universities">| Find Universities</a>
                  </li>
                  <li>
                    <a href="/scholarships">| Scholarships</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 mt_mobile--30">
              <div className="footer-link">
                <h4 style={underlineText}>CONTACT US</h4>
                
                <ul className="ft-link" style={linkList}>
                  <li>
                    <p style={footerText}><FiPhone/> +977-9855053942 , +977-1-4480660</p>
                  </li>
                  <li>
                  <p style={footerText}>
                    <FiMapPin/> New Baneshwor Chowk, Kathmandu, Nepal
                    </p>
                  </li>
                  <li>
                  <p style={footerText}><FiMail/> info@avenue.edu.np</p>
                  </li>
                  <li>
                  <p style={footerText}> <FiClock/> Mon-Fri / 7:00AM - 6:00PM</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 mt_mobile--30 ">
              <div className="footer-link">
                <div>
                <h4  style={underlineText}>Our Global Presence </h4>
                  <p style={footerText}>Nepal: Kathmandu, Chitwan, Pokhara and Butwal</p>
                  <p style={footerText}>Australia: Sydney</p>
                </div>

                <div className="social-share-inner">
                  <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                    {SocialShare.map((val, i) => (
                      <li key={i}>
                        <a href={`${val.link}`}>{val.Social}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-right mt_md--20 mt_sm--20">
              <div className="text">
                <p>
                  © 2022 Avenue Educational Consultancy All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component, Fragment } from "react";
import AveSlider from "../avenue/homePage/AveSlider";
import AveService1 from "../avenue/homePage/AveService1";
import AveAboutUs from "../avenue/homePage/AveAboutUs";
import AveCounter from "../avenue/homePage/AveCounter";
import AveContactForm from "../avenue/homePage/AveContactForm";
import AveService2 from "../avenue/homePage/AveService2";
import AveCourses from "../avenue/homePage/AveCourses";
import AveDestination from "../avenue/homePage/AveDestination";
import AveNewsAndEvents from "../avenue/homePage/AveNewsAndEvents";
import CustomerFeedback from "../avenue/homePage/CustomerFeedback";
import HeaderFive from "../component/header/HeaderFive";


class Home extends Component { 
  render() {
    return (
      <Fragment>
        <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
    {/* Start Slider Area   */}
    <AveSlider/>
        {/* End Slider Area   */}
        <div class="pt--120">

        {/* Start Service Area */}
      <AveService1/>
        {/* End Service Area */}

         {/* Start About Us Area */}
      <AveAboutUs/>
        {/* End About Us Area */}

          {/* Start CounterUp Area */}
      <AveCounter/>
        {/* End CounterUp Area */}

         {/* Start Destination Area */}
      <AveDestination/>
        {/* End Destination Area */}

         {/* Start Courses Area */}
         <AveCourses/>
                {/* End Courses Area */}

        {/* Start Contact Us Area*/}
        <AveContactForm/>
                {/* End Contact Us Area*/}

                 {/* Start Service 2 Area */}
        <AveService2/>
                {/* End Service 2 Area */}

                 {/* Start News and Events Area */}
        <AveNewsAndEvents/>
                {/* End News and Events Area */}

                 {/* Start CustomerFeedback Area */}
        <CustomerFeedback/>
                {/* End CustomerFeedback Area */}  
                </div>    
      </Fragment>
    );
  }
}
export default Home;



        
        


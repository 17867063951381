import React from 'react'
import ArticlePage from '../components/ArticlePage'
const describe=<div>
    <p>Studying in Canada is a dream for many students because it is one of the top destinations to start your career. But getting into one of the universities/colleges of Canada with an effective application process is a tough job.</p>
<p>Applying for Canada with proper guidance doesn’t have to be that Complicated and hectic process. Canada is the top destination for Nepalese and other international Students for their Higher Education.</p>
<p>Really, Does it always have to be tough?</p>
<h3>Help me to Study in Canada.</h3>
<p>Are you trying to apply to study in Canada on your own? If yes, then you are sure to make a lot of mistakes in the application process.
Trust us on this one, Canada has some great opportunities for students where learning and growth go parallelly.
You might be making lots of mistakes in the visa process if you are on your own. Here are some of the top mistakes that students make during the application process for study in Canada.
</p>
<h3>1. Lack of proper research in Application Process</h3>
<p>If you think without proper research you will be getting a visa for Canada, think again.
 Research is the most crucial part of your visa application process. Actually, you have to spend more time doing research than the actual visa process.
</p>
<p>Research to study in Canada is Never Enough.</p>
<p>If you are not taking help from any resource the most hectic process is doing the research on your own. You have to find out the university and the course location. The facilities provided by the colleges and universities, cost of living in Canada, the visa application process, their lifestyle are something that can’t be avoided either. In conclusion, research is the backbone for your successful visa acceptance in Canada.</p>
<h3>Unclear intentions to comeback</h3>
<p>Commitment is always a good foundation for any relationship. The same goes out for your application process. 
The purpose of your application process is to apply for Canada for attaining education.
You might have a change of plans and decide to stay in the country after your studies. If you drift away from your commitment as stated prior in the application form of returning back to your home country, then chances of your visa rejection is high. So you should never mention anywhere that you will be staying back in the country.
You have to make sure that your intention is to come back to your native country. You should be able to convince them that you will come back to your own country before the expiration date of your visa. These details are to be mentioned clearly on the SOP/CV.
</p>
<h3>3. Choice of College, University, and Program to study in Canada</h3>
<p>The major part of the application process is to choose the universities and colleges along with the course for your education in Canada. You might get rejected by the college for applying to the wrong subject because of your academics in your native country. So you should be careful while choosing the subject to study in Canada.</p>
<p>Which one to Choose??</p>
<p>You would also want to make sure that the program you choose is suitable and meet all the basic course’s pre-requirements.
A complete change in your subject can create a bit of suspicion to the concerned authority. However, you can always have a strong and valid reason to stick to your choice and convince them.
</p>
<h3>4. Failing to provide essential documents during the visa process</h3>
<p>Students all around the globe apply to Canada for studies and most of them are rejected because of the invalid or incomplete documents. The most important part of getting a visa is making your document strong and genuine.</p>
<p>Here are some of the tips for maintaining your documents before applying for visa:</p>
<ul>
    <li>Acquire all the required information related to the visa processing</li>
    <li>Submit authentic documents only</li>
    <li>Provide maximum evidence as possible through documentation</li>
</ul>
<p>It is extremely important to show strong documentation because 95% of the visa selection is based on your documentation. You need to contain all the minute details in your documentation. So that you don’t waste your time further maintaining your documentation.</p>
<h3>5. Choosing an unregulated or poor consultant as your visa guide</h3>
<p>Applying abroad is a huge decision as your future depends on it. So choosing a credible and trustworthy educational consultancy is a must.
Canada!!! Here I come.
</p>
<p>A reliable and regulated educational consultancy has all the ideas for guiding you through the hectic process of visa guidance easily. You shouldn’t worry about your documentation, university, course, or other small details. Your consulting partner will guide you with all the necessary processes for your visa processing for Canada.
This is where Avenue Consultancy comes into the picture. Avenue Consultancy is one of the reliable and authorized consultation partners for your visa guidance. You won’t have to bear all the hectic processes yourself if you are trying to apply for Canada. Our experienced visa experts will guide you through all the necessary processes easily.
</p>
<h3>Major Takeaway:</h3>
<p>Getting Selected in a Canadian University takes a lot of real work. Proper guidance can really ease the process. Canada can be a great study destination for you. The great progress in the academic section, world-renowned University, college, and faculty members makes it more interesting to study in Canada.</p>
<p>If you are trying to study in Canada you can choose Avenue Consultancy without any hesitation.</p>
<p>And remember to avoid these top mistakes during the application process for studying in Canada. </p>
<p>Feel free to contact us for guidance and apply for a Canadian student visa from Nepal.</p>
</div>

export default function FiveMistakes() {
    return (
        <div>
             <ArticlePage 
            image="/assets/aveImages/articles/5mistakes.jpg"
            title="Top 5 Mistakes in Application Proces to Study in Canada"
            description={describe}
            next1title="Selecting the best University"
            next1description="Blog"
            next1image="/assets/aveImages/articles/selectingBestUni.jpg"
            next1link="selectingUni"
            next2title="How to Apply for a Master's Degree in Australia?"
            next2description="Blog"
            next2image="/assets/aveImages/articles/applyMasterAustralia.jpg"
            next2link="applyMaster"
            next3title="Some must-dos before choosing a study abroad destination"
            next3description="Blog"
            next3image="/assets/aveImages/articles/mustDos.jpg"
            next3link="mustDos"
             next4title="Top 10 Master's Courses for International Students"
            next4description="Blog"
            next4image="/assets/aveImages/articles/masterCourses.jpg"
            next4link="masterCourses"
            />
        </div>
    )
}

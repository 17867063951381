import React from 'react'
import HeaderFive from '../../../component/header/HeaderFive';
import ApplyNow from '../../components/ApplyNow';
import LeftTextDescription from '../../components/LeftTextDescription'
import RightTextDescription from '../../components/RightTextDescription'
import "../../css/text.css";
const Background ="/assets/aveImages/mainImage/pte.png";

const intro=<div>
  <h3 className="headingStyle">What exactly is it?</h3>
<p>
The TOEFL iBT® test, which is taken online, is an important step on your path to studying in an English-speaking country. The ETS TOEFL® Program offers tools and guides to help you prepare for the test and improve your English language skills in addition to the test. The TOEFL iBT test assesses your ability to communicate in English at a university level. It also assesses your ability to perform academic tasks by combining your listening, reading, speaking, and writing skills. The TOEFL® test has been taken by more than 30 million people from all over the world to demonstrate their English language proficiency. The average English proficiency level is Intermediate to Advanced.
</p>
</div>

const intended=<div>
  <h3 className="headingStyle">Who is it intended for?</h3>
<ul>
  <li>Students who intend to attend a post-secondary institution.</li>
  <li>Admissions to and exit from English-language learning programs.</li>
  <li>Candidates for scholarships and certification.</li>
  <li>Learners of English who want to keep track of their progress.</li>
  <li>Applicants for visas include students and workers</li>
</ul>
</div>


const test=<div>
  <h3 className="headingStyle">TOFEL Tests</h3>
  <ul>
    <li>Scores Help You Stand Out</li>
    <li>Rates Speaking More Fairly</li>
    <li>Measures Academic Skills</li>
    <li>More Choices and More Convenient</li>
  </ul>
</div>


export default function Tofel() {
    return (
        <div>
             <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
          
        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--110 bg_image"  data-black-overlay="1"
         style={{backgroundImage: `url(${Background})`,}}
        >
           <div className="container" >
           <div className="col-lg-6 pt--110 " >
                       <div className="rn-page-title pt--100" >
                           <h2 className="title " style={{color:"white"}}>TOFEL</h2>
                       </div>
                   </div>
           </div>
       </div>
       {/* End Breadcrump Area */}
       <div className="ptb--120">
       <RightTextDescription description={intro} image="/assets/aveImages/tofel/tofel1.png"/>
       <div className="pb--110">
       <LeftTextDescription description={intended} image="/assets/aveImages/tofel/tofel2.png"/>
       </div>
       <RightTextDescription description={test} image="/assets/aveImages/tofel/tofel3.png"/>
         <ApplyNow/>
       </div>
   </div>
    )
}

import React from "react";
import LeftTextDescription from "../../components/LeftTextDescription";
import RightTextDescription from "../../components/RightTextDescription";
import "../../css/text.css";
import HeaderFive from "../../../component/header/HeaderFive";

const Background = "/assets/aveImages/mainImage/usa.png";

const whyUsa=<div>
    <p>Often regarded as the leader in the free world, the United States has a large number of higher education institutions and universities with globally recognized degrees. There are four types of educational institutions in the United States,  each with different learning options and affordable levels for each student. </p>
<ul>
    <li>State University / University </li>
    <li>Private university / university </li>
    <li>Adult Education Center </li>
    <li>Technical institution</li>
</ul>
<p>The United States has more than 4,500 institutions and universities that offer 10 times more higher education than any other country. And if you want to continue your education, you can complete a master's degree (an additional two years) or a doctoral degree (an additional one to four years) in an incredible number of disciplines.</p>
</div>

const visaRequirements=<div>
    <p> Student visas issued in the United States fall into two categories. Student visas in the USA "F" category are for students attending schools, universities, universities, or other academic institutions, including language programs, and student visas in the USA "M" category are for professional and non-academic programs. </p>
<p> To obtain a US student visa, you must follow the steps below according to your order. </p>
<ul>
    <li>Take an IELTS or  other accepted English test </li>
    <li>Receive  i20 documents </li>
    <li>Collect student visa documents </li>
    <li>Fill out the DS 160 form and book a visa interview </li>
    <li>Participate in a visa interview </li>
    <li>Receive your passport</li>
</ul>
</div>

const employmentProspects=<div>
    <p>If you have an F1 visa, you will be allowed to work in the United States with the approval of certain school officials, but  under certain conditions, only in accordance with the complex guidelines and restrictions of the U.S. Citizenship and Immigration Department.</p>
</div>

const workingAsStudent=<div>
    <p>As an F1 student in the United States, there are several types of employment. On-campus tracking is available free of charge, but off-campus tracking is relevant to your field of study and must be approved by the school's executive designation. You need a Social Security Number (SSN) to work in the United States. However, you can  apply for a job without an SSN and still receive an SSN using a job  after being hired.  Student visas typically allow 20 hours of work per week. However, please check  your visa details before you start working</p>
</div>

const cpt=<div>
    <p> If you are enrolled in a full-time US university  for at least one grade, you may be able to apply for Curricular Practical Training (CPT). CPT is  an integral part of a degree program and is a profession that allows you to participate in an internship, internship, or dual study course. You need to receive  credit for your work and you need to complete the CPT  before you graduate. </p>
</div>

const workingOnCampus=<div>
    <p>You can work  up to 20 hours  a week as a teaching assistant, university bookstore, barista, undergraduate assistant, or any other support permitted by your school. On-campus jobs are typically updated online at the University Job Exchange, the Campus Student Center, or the University Portal. You can get more information about vacancies from your college career services. 
</p>
</div>

const internshipProgram=<div>
    <p>Almost every university has a system that allows all students to get an internship while studying. In fact, some offer internship programs that are mandatory for everyone as part of their after-school activities.</p>
<table>
    <thead>
        <tr>
            <th>S.No.</th>
            <th>Education Cost</th>
            <th>Tuition fees in USD (indicative)</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1</td>
            <td>English language courses</td>
            <td>$700 to $2,000 a month</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Undergraduate course</td>
            <td>$20,000 to $45,000 per year</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Graduate programs</td>
            <td>$20,000 to $50,000 per year</td>
        </tr>
        <tr>
            <td>4</td>
            <td>Doctoral degree</td>
            <td>$28,000 to $60,000 per year</td>
        </tr>
    </tbody>
</table>

<table>
    <thead>
        <tr>
            <th>S.No.</th>
            <th>Accommodation costs and living costs</th>
            <th>expenses (approx)</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1</td>
            <td>a one-bedroom apartment in a built-up city</td>
            <td>$3,500</td>
        </tr>
        <tr>
            <td>2</td>
            <td>On-campus rooms</td>
            <td>between $5,304 and $8,161</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Internet provision costs</td>
            <td>$35-60 per month</td>
        </tr>
        <tr>
            <td>4</td>
            <td>household and monthly phone bills</td>
            <td>around $50 per month</td>
        </tr>
         <tr>
            <td>5</td>
            <td>monthly public transport pass costs</td>
            <td>between $50 and $60</td>
        </tr>
         <tr>
            <td>6</td>
            <td>Weekly groceries</td>
            <td>between $20 and $70</td>
        </tr>
         <tr>
            <td>7</td>
            <td>average monthly gym membership</td>
            <td>$58</td>
        </tr>
    </tbody>
</table>
</div>

export default function StudyAbroadUsa() {
  return (
    <div>
      <HeaderFive
        headerPosition="header--static logoresize"
        color="color-black"
      />
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--110 bg_image"
        data-black-overlay="1"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="container">
          <div className="col-lg-12">
            <div className="rn-page-title text-center pt--100">
              <h2 className="title " style={{ color: "white" }}>
                USA
              </h2>
              <h3 style={{ color: "white" }}>
                A country of dreams and opportunities, providing quality
                education to students around the world.
              </h3>
              <p>
                Whether it's the quality of education or the outlook for a
                career after graduation, the United States has attracted the
                attention of most future students abroad.
              </p>
              <div className="about-button mt--50">
                <a
                  className="rn-button-style--2 btn-solid"
                  href="/studyAbroadWithAvenue"
                >
                  Speak with us Today
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      <LeftTextDescription
        heading="WHY IS THE USA A TOP STUDY DESTINATION FOR NEPALIS?"
        description={whyUsa}
        image="/assets/aveImages/usa/usaTopDestination.jpg"
      />
      <div className="pt--110">
        <RightTextDescription
          heading="STUDENT VISA REQUIREMENTS IN THE USA FROM NEPAL"
          description={visaRequirements}
          image="/assets/aveImages/usa/studentVisaRequirement.jpg"
        />
      </div>
        <LeftTextDescription
        heading="EMPLOYMENT PROSPECTS IN THE USA"
        description={employmentProspects}
        image="/assets/aveImages/usa/employmentProspects.jpg"
      />
      <div className="pt--110">
        <RightTextDescription
          heading="WORKING AS A STUDENT"
          description={workingAsStudent}
          image="/assets/aveImages/usa/workingAsAStudent.jpg"
        />
      </div>
       <LeftTextDescription
        heading="CURRICULAR PRACTICAL TRAINING (CPT)"
        description={cpt}
        image="/assets/aveImages/usa/cpt.jpg"
      />
      <div className="pt--110">
        <RightTextDescription
          heading="WORKING ON CAMPUS"
          description={workingOnCampus}
          image="/assets/aveImages/usa/workingOnCampus.jpg"
        />
      </div>
       <div className="col-lg-12 ptb--110 container">
          <div className="rn-about-wrapper textAlign">
            <div style={{ paddingBottom: "30px" }}>
              <h2 style={{ textAlign: "center", fontSize: "40px" }}>
               INTERNSHIP PROGRAM
              </h2>
            </div>
            <div className="about-inner inner">
              <div className="section-title">{internshipProgram}</div>
            </div>
          </div>
        </div>
    </div>
  );
}

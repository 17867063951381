import React from 'react'
import "../css/text.css"

export default function ApplyNow() {
    return (
        <div className="text-center pt--110">
        <h2 className="applytitle">TAKE YOUR FIRST STEP TOWARDS SUCCESS</h2>
        <p>Avenue Consultancy can help you enroll in a Preparation Class.</p>
        <div className="about-button mt--50">
        <a className="rn-button-style--2 btn-solid" href="/studyAbroadWithAvenue">
         APPLY NOW
        </a>
      </div>
    </div>
       
    )
}

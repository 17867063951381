import React from 'react'
import ArticlePage from '../components/ArticlePage'

const describe=<div>
   <h3>Step 1: Getting Ready</h3>
   <p>For any Nepalese student, who would like to pursue their higher education in an English speaking country, you will have to meet the criteria of minimum English Language Proficiency. There are different such tests like IELTS, TOEFL or PTE. One can choose either one. The validity of these scores will be for two years. The minimum requirement might depend on the choice of your courses and your choice of university. Apart from the language requirement, you will also have to have your complete set of academic documents and work experiences (if any).</p>
   <h3>Step 2: Choice of Course and University</h3>
   <p> Once you have all your credentials ready, decide what exactly you want to study. This choice can be based on, either your previous qualification or your past work experience (if any). The Universities at this stage will not be very demanding of the academic gap year if you have the evidence of engagement in any relevant job. For courses like MBA, a relevant work experience sometimes becomes mandatory as an admission requirement. In order to make the best choice, you can always seek the guidance of your seniors or counselors who are better aware of the current situation regarding good career growth.</p>
   <p>Once you are sure of the course, you will have to start looking for the Universities where the related courses are provided. All the universities might not have satisfactory options. Besides, things like ranking, accreditation, location, tuition fee, internship opportunities, industry partners, are amongst the topmost criteria that need to be well researched before you make the final choice. Again, if you have confusion about any of the above, an experienced counselor can help you provide the needful information.</p>
   <h3>Step 3: Apply for the Offer Letter</h3>
   <p>You will have to send in your academic documents, English language proficiency test scores, and other credentials to seek a placement in the program. This is often known as the offer letter, which shall be issued by the academic department of the university. The Letter of Offer might have some conditions or can be unconditional as well. In case any of the documents are incomplete or there might be criteria like interviews that the student needs to face, you shall receive a conditional offer, and to qualify for the next step you will have to fulfill the condition under a fixed time period.</p>
   <h3>Step 4: Prepare your funds/Genuine Temporary Entrant</h3>
   <p>The University as well as the Visa Officers need to be assured that you have enough funds to study and meet other living expenses while you are in their country as a student. You can either apply for an education loan or in a few exceptional cases, you can also show a savings account having the required amount. The majority of this fund should be either in your name or your parents. Along with this, you shall have to show proof of other income support as well. Besides the financial documents, you shall have to submit a well-written Statement of Purpose. On a few occasions, the university might conduct a skype interview where you will need to justify the Statement of Purpose. Once the team is convinced that you are a genuine student having enough funds and good intentions, it shall be followed by the next step.</p>
   <h3>Step 5: Payment of Tuition fee and receiving confirmation of enrollment</h3>
   <p>When the assessment team at the university is convinced of your intentions and funds, they shall require you to make a payment against the first semester of the tuition fee. For this, you shall have to make a “No Objection Letter” from the Ministry of Education. The banks will then help you with the payment. Once the payment is received, the University shall provide you with the confirmation of enrollment. The counselor at this stage will help you to prepare all the needful documents for the visa application.</p>
   <h3>Step 6: Visa Application</h3>
   <p>You shall require to make an application for the Student Visa at the Australian High Commission, New Delhi. It might take any time between 24 hours to a month for the visa decision to be received.</p>
   <h3>Step 7: Make travel arrangements and find your accommodation</h3>
   <p>Secure your flights well before the orientation date to be on schedule and to travel at your convenience. It is also advised to confirm your accommodations; this can be done either in the Campus accommodation or the counselors might help you arrange one outside the campus.</p>
<p>The first thing to do once you arrive in Australia is to pay a visit to the respected university and meet the admission team, get enrolled, and don’t forget to attend the orientation program that is generally provided by almost all the universities.
We hope this article has been helpful to our students with questions of how to apply for a master’s degree in Australia? For further information, you can always place your queries through email or visit Avenue Consultancy in person at your convenience.
</p>
</div>

export default function ApplyMasterAustralia() {
    return (
        <div>
             <ArticlePage 
            image="/assets/aveImages/articles/applyMasterAustralia.jpg"
            title="How to Apply for a Master's Degree in Australia?"
            description={describe}
            next1title="Selecting the best University"
            next1description="Blog"
            next1image="/assets/aveImages/articles/selectingBestUni.jpg"
            next1link="selectingUni"
            next2title="Top 5 Mistakes in Application Proces to Study in Canada"
            next2description="Blog"
            next2image="/assets/aveImages/articles/5mistakes.jpg"
            next2link="fiveMistakes"
            next3title="Some must-dos before choosing a study abroad destination"
            next3description="Blog"
            next3image="/assets/aveImages/articles/mustDos.jpg"
            next3link="mustDos"
             next4title="Top 10 Master's Courses for International Students"
            next4description="Blog"
            next4image="/assets/aveImages/articles/masterCourses.jpg"
            next4link="masterCourses"
            />
        </div>
    )
}

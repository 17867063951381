import React,{Component} from 'react';
import "../../css/text.css";
import {
    FiMail,  
    FiPhone,
    FiClock,
    FiMapPin,
  } from "react-icons/fi";

  const linkList = {
    listStyle: "none",
    marginLeft: "0px",
    paddingLeft: "0px",
  };

export default class ContactForScholarship extends Component {
    render() {
        return (
            <div>
                <div className="rn-about-area">
                    <div className="container">
                        <div className="row pb--110 textAlign">
                            <div className="col-lg-6">
                            <h3 className="section-title">Contact For Scholarship</h3>
                            <div>
                
                <ul  style={linkList}>
                  <li>
                    <p><FiPhone/> +977-9855053941, +977-1-4480660</p>
                  </li>
                  <li>
                   
                    <p><FiMapPin/> New Baneshwor Chowk, Kathmandu, Nepal</p>
                  
                  </li>
                  <li>
                 <p><FiMail/> info@avenue.edu.np</p>
                  </li>
                  <li>
                  <p> <FiClock/> Mon-Fri / 7:00AM - 6:00PM</p>
                  </li>
                </ul>
              </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

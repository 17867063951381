import React from 'react'
import HeaderFive from '../../../component/header/HeaderFive';
import ApplyNow from '../../components/ApplyNow';
import LeftTextDescription from '../../components/LeftTextDescription'
import RightTextDescription from '../../components/RightTextDescription'
import "../../css/text.css";
const Background ="/assets/aveImages/mainImage/sat.jpg";

const intro=<div>
  <h3 className="headingStyle">What exactly is it?</h3>
<p>The Scholastic Assessment Test, better known as the SAT, is an entrance exam created and managed by the College Board that tests a high school student’s readiness for university. College admission departments will use SAT scores alongside other parts of your student profile to consider your application; SAT scores are compared to one another as the scores are essentially part of one data set that can be used to calculate important information like the mean/average score, as well as the score distribution with percentiles assigned to each score range. While some show up in pajamas with zero hours of preparation simply because it is unimportant for their future, others spend months with tutors and prep books as a good score may be their gateway to the college of their choice. Fretting already? Here is everything you need to know about the SAT. </p>
</div>

const intended=<div>
  <h3 className="headingStyle">Registering for the test</h3>
<p>In the United States, the vast majority of schools will require or highly encourage students to take the SAT; therefore, they will guide them in the registration process. In the case of an overseas student, the SAT isn’t as common as it is in America, which is why this process may be confusing to several. If you’re comfortable with technology, the first option to consider is to register directly through College Board, the website is very student-friendly so it wouldn’t be much of a hassle to register on it. If you’re unable to register independently, the second option is to go to any authority at your school that knows about tests like the SAT. The final option is to find a study-abroad agency near you, like one of the many Alfa Beta branches in Kathmandu, who will provide you with qualified counselors to aid you in the registration process. </p>
</div>

const test=<div>
  <h3 className="headingStyle">Paying for the test</h3>
<p>As an overseas student, the fee structure to sit at a test center and write the three-hour-long SAT test is as follows: a flat 49.50 USD for the SAT without essay, or a flat $64.50 for the SAT with the essay—on top of the set fees, you must pay a “Non-US Regional Fee” which varies according to the region of the world that you’re taking the test in, ranging from $43 to $53. Other than that, there are additional fees that you may be charged; the test-answer service, changing of test dates, or the sending of test scores to universities all require you to pay additional fees. The SAT is undeniably pricey, especially if you live overseas; yet it may as well be a great investment if you’re able to score well and use the score to get accepted into the school of your choice.</p>
</div>

export default function Sat() {
    return (
        <div>
             <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--110 bg_image"  data-black-overlay="1"
         style={{backgroundImage: `url(${Background})`,}}
        >
           <div className="container" >
           <div className="col-lg-6 pt--110 " >
                       <div className="rn-page-title pt--100" >
                           <h2 className="title " style={{color:"white"}}>SAT</h2>
                       </div>
                   </div>
           </div>
       </div>
       {/* End Breadcrump Area */}
       <div className="ptb--120">
       <RightTextDescription description={intro} image="/assets/aveImages/sat/sat1.png"/>
       <div className="pb--110">
       <LeftTextDescription description={intended} image="/assets/aveImages/sat/sat2.png"/>
       </div>
       <RightTextDescription description={test} image="/assets/aveImages/sat/sat3.png"/>
         <ApplyNow/>
       </div>
   </div>
    )
}

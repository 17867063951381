import React, { Component } from 'react';
import Contact from "../../elements/contact/ContactTwo";

export default class AveContactForm extends Component {
    render() {
        return (
            <div>
                
                              {/* Start Contact Us */}
                              <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <Contact title="Contact Us" screen="home"/>
                </div>
                {/* End Contact Us */}
            </div>
        )
    }
}

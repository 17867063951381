import React, { Component } from 'react'
import "../css/text.css";


export default class QuickFacts extends Component {
    render() {
        return (
            <div>
                    {/* Start About Area  */}
                    <div className="rn-about-area pt--110 pb--110 bg_color--1">
                    <div className="rn-about-wrapper textAlign">
                    <div style={{paddingBottom:"30px"}}><h2 style={{textAlign:"center", fontSize:"40px"}}>QUICK FACTS</h2></div>
                        <div className="container">
                        
                            <div className="row row--35">
                            
                            <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        {this.props.leftFacts}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            {this.props.rightFacts}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                
            </div>
        )
    }
}

import React from "react";
import HeaderFive from "../../../component/header/HeaderFive";
import LeftTextDescription from "../../components/LeftTextDescription";
import RightTextDescription from "../../components/RightTextDescription";
import "../../css/text.css";

const Background = "/assets/aveImages/mainImage/newzealand.png";

const educationSystem = (
  <div>
    <p>
      New Zealand is home to many modern universities that are world leaders in
      research and innovation. All eight New Zealand universities for
      international students are government agencies, including Auckland
      University, Auckland University of Technology (AUT),{" "}
    </p>
    <p>
      Waikato University, Massey University, Victoria Wellington University,
      Canterbury University, Lincoln University, and Otago University. Funding.
      Therefore, they have excellent resources to provide a quality education
      system for New Zealand students.{" "}
    </p>
    <p>
      New Zealand PhD and MBA courses are also recognized worldwide and your
      degree is of quality guaranteed by the New Zealand Government.
    </p>
  </div>
);

const whyNewzealand = (
  <div>
    <p>
      New Zealand is attracting attention as a study abroad destination. This is
      the reason!{" "}
    </p>
    <p>
      Everyone knows that New Zealand is a beautiful country, but it's not just
      the picturesque scenery. It is becoming one of the main favorites among
      Nepali students who are thinking of studying abroad. With an excellent
      education system, internationally recognized bachelor's degree, extensive
      research opportunities and unmatched quality of life, New Zealand has much
      to offer. While studying, you can count on a welcoming and friendly
      company with an excellent support system for international students.{" "}
    </p>
  </div>
);

const worldwideRecognized = (
  <div>
    <p>
      All eight universities in New Zealand are consistently high in the QS
      World Rankings * and Times Higher Education Rankings, and these degrees
      are accredited around the world, opening the door to prospects around the
      world. The New Zealand Qualifications Authority (NZQA) also ensures that
      institutions are enrolled to enroll international students and meet
      qualification criteria.{" "}
    </p>
  </div>
);

const excellentPedagogy = (
  <div>
    <p>
      Under the influence of the UK education system, New Zealand is committed
      to research-based education. H. You learn to deal with situations in a
      systematic and systematic way. Learn how to work independently and in
      teams of different levels. By studying here, you can think critically, ask
      questions, and benefit from student-teacher interactions. It also
      implements the concepts learned in the class and develops different
      approaches to solving problems.
    </p>
  </div>
);

const innovationResearch = (
  <div>
    <p>
      New Zealand offers many research opportunities for students. The country
      has highly experienced faculties, well-equipped laboratories, and access
      to the latest technology, equipment and facilities. With its spirit of
      innovation, it has become the center of new technology and research and
      development in various fields such as healthcare, physics, geology,
      engineering, astronomy, computer science, and agriculture. Adding the
      Nobel Prize to this can bring the country back to the importance and scope
      of research in New Zealand.
    </p>
  </div>
);

export default function StudyAbroadNewzealand() {
  return (
    <div>
      <HeaderFive
        headerPosition="header--static logoresize"
        color="color-black"
      />
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--110 bg_image"
        data-black-overlay="1"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="container">
          <div className="col-lg-12">
            <div className="rn-page-title text-center pt--100">
              <h2 className="title " style={{ color: "white" }}>
                NEWZEALAND
              </h2>
              <h3 style={{ color: "white" }}>
               New Zealand, which ranks second in the 2019 Global Peace Index, is arguably the safest country to study.
              </h3>
              <p>
   With a globally acclaimed university and a responsive education system, we provide Indian students with the right combination of opportunities, culture, economy and climate. Not to be missed is the pristine landscape that makes your experience worth every penny.
              </p>
              <div className="about-button mt--50">
                <a
                  className="rn-button-style--2 btn-solid"
                  href="/studyAbroadWithAvenue"
                >
                  Speak with us Today
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}
      <LeftTextDescription
        heading="EDUCATION SYSTEM FOR STUDYING IN NEW ZEALAND"
        description={educationSystem}
        image="/assets/aveImages/newzealand/educationSystem.jpg"
      />
      <div className="pt--110">
        <RightTextDescription
          heading="WHY STUDY IN NEW ZEALAND?"
          description={whyNewzealand}
          image="/assets/aveImages/newzealand/whyNewZealand.jpg"
        />
      </div>
      <LeftTextDescription
        heading="WORLDWIDE RECOGNIZED DEGREE"
        description={worldwideRecognized}
        image="/assets/aveImages/newzealand/worldwideRecognized.jpg"
      />
      <div className="pt--110">
        <RightTextDescription
          heading="EXCELLENT PEDAGOGY"
          description={excellentPedagogy}
          image="/assets/aveImages/newzealand/excellentPedagogy.jpg"
        />
      </div>
      <div className="pb--110">
        <LeftTextDescription
          heading="INNOVATION AND RESEARCH"
          description={innovationResearch}
          image="/assets/aveImages/newzealand/innovationResearch.jpg"
        />
      </div>
    </div>
  );
}

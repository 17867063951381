import React from 'react'

export default function ArticleCard({description, title, buttonLink, image}) {
    return (
        <div className="col-lg-4 col-md-4 mt--30">
        <div className="standard-service">
          <div className="thumbnai">
            <img
              src={image}
              alt="service Images"
            />
          </div>
          <div style={{paddingTop:"20px"}} >
          <p className="textAlign">{description}</p>
            <h3 style={{color:"#3f47cc"}}>
                {title}
            </h3>

            <div className="about-button ">
                            <a className="rn-button-style--2 btn-solid" href={buttonLink}>Read More</a>
                        </div>
            {/* <a
              className="btn-transparent rn-btn-dark"
              href="/"
            >
              <span className="text" style={{color:"#3f47cc"}}>Find out More</span>
            </a> */}
          </div>
        </div>
      </div>
    )
}

import React, { Component } from "react";
import {NavLink as Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

const linkColor = {
  color: "#3f47cc",
};

class HeaderFive extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
   //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener('load', function() {
        console.log('All assets are loaded')
    })
}
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu').classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    const {  color , headerPosition } = this.props;

    return (
      <header
        className={`header-area formobile-menu ${headerPosition} ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-4 col-6">
                <div className="header-left">
                  <div className="logo">
                    <a href="/">
                      <img
                        src="/assets/aveImages/companyLogo/avenueLogo.png"
                        alt="avenue"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-6">
                <div className="header-right justify-content-end">
                  <nav className="mainmenunav d-lg-block">
                    <ul className="mainmenu">
                      <li>
                        <Link exact activeStyle={linkColor} to="/">
                          Home
                        </Link>
                      </li>

                      <li className="has-droupdown">
                        <Link exact to="#">
                          Explore
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link
                              exact
                              activeStyle={linkColor}
                              to="/scholarships"
                            >
                              Scholarships
                            </Link>
                          </li>
                          <li>
                            <Link
                              exact
                              activeStyle={linkColor}
                              to="/universities"
                            >
                              Education Partners{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link exact to="/aboutUs" activeStyle={linkColor}>About Us</Link>
                      </li>

                      <li className="has-droupdown">
                        <Link exact to="#">
                          Study Abroad
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link
                              exact
                              activeStyle={linkColor}
                              to="/studyAbroadWithAvenue"
                            >
                              Study Abroad With Avenue
                            </Link>
                          </li>
                          <li>
                            <Link
                              exact
                              activeStyle={linkColor}
                              to="/studyAbroadAustralia"
                            >
                              Study at Australia{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              exact
                              activeStyle={linkColor}
                              to="/studyAbroadCanada"
                            >
                              Study at Canada{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              exact
                              activeStyle={linkColor}
                              to="/studyAbroadUk"
                            >
                              Study at UK{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              exact
                              activeStyle={linkColor}
                              to="/studyAbroadUsa"
                            >
                              Study at USA{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              exact
                              activeStyle={linkColor}
                              to="/studyAbroadNewzealand"
                            >
                              Study at Newzealand{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              exact
                              activeStyle={linkColor}
                              to="/studyAbroadIreland"
                            >
                              Study at Ireland{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/articles" exact activeStyle={linkColor}>
                          Articles
                        </Link>
                      </li>

                      <li className="has-droupdown">
                        <Link exact to="#">
                          Our Services
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link exact activeStyle={linkColor} to="/ielts">
                              IELTS
                            </Link>
                          </li>
                          <li>
                            <Link exact activeStyle={linkColor} to="/pte">
                              PTE
                            </Link>
                          </li>
                          <li>
                            <Link exact activeStyle={linkColor} to="/gmat">
                              GMAT
                            </Link>
                          </li>
                          <li>
                            <Link exact activeStyle={linkColor} to="/tofel">
                              TOFEL
                            </Link>
                          </li>
                          <li>
                            <Link exact activeStyle={linkColor} to="/sat">
                              SAT
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>

                  {/* Start Humberger Menu  */}
                  <div className="humberger-menu d-block d-lg-none pl--20">
                    <span
                      onClick={this.menuTrigger}
                      className="menutrigger text-white"
                    >
                      <FiMenu />
                    </span>
                  </div>
                  {/* End Humberger Menu  */}
                  <div className="close-menu d-block d-lg-none">
                    <span
                      onClick={this.CLoseMenuTrigger}
                      className="closeTrigger"
                    >
                      <FiX />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderFive;

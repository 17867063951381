import React, { Component } from 'react'
import "../css/text.css"

export default class AveAboutUs extends Component {
    render() {
        return (
            <div>
                   {/* Start About Area  */}
        <div className="rn-about-area pb--110 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/aveImages/aboutUs/aboutUs.png"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h3 className="title" style={{lineHeight:1.1}}>
                      Welcome to Avenue Educational Consultancy
                    </h3>
                    <h5 className="title textAlign">Nepal's Leading Educational Service Provider</h5>
                    <p className="description textAlign">
                      Avenue Educational Consultancy was founded with the goal
                      of providing high-quality educational services to students
                      pursuing further education in Australia, New Zealand, USA,
                      UK, Ireland, Japan, Switzerland, Finland, Norway, Germany,
                      Denmark, Poland, Cyprus, Singapore and some others. We
                      also offer TOEFL, IELTS, SAT, GRE and GMAT preparation
                      classes.
                    </p>
                  </div>

                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/aboutUs">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
            </div>
        )
    }
}


import React from "react";
import HeaderFive from "../../../component/header/HeaderFive";
import LeftTextDescription from "../../components/LeftTextDescription";
import RightTextDescription from "../../components/RightTextDescription";
import "../../css/text.css";

const Background = "/assets/aveImages/mainImage/ireland.png";

const tutionFees = (
  <div>
    <p>
      If you want to study abroad in Ireland, the first thing you need to know
      is how much money you will need. For simplicity, we have done a survey for
      you. Read on for more information on tuition and living expenses for
      International students
    </p>
    <p> Bachelor's degree: 9,850- 55,000 EUR / year </p>
    <p>Master's and PhD: 9,950 euros - 35,000 / year </p>
    <p>
      {" "}
      All international students (both EU / EEA and non-EU / EEA citizens) are
      required to pay up to € 3,000 / year for student services such as exam
      registration and association support. Fees vary from university to
      university and may change from year to year.
    </p>
  </div>
);

const whyIreland = (
  <div>
    <p>Ireland is a friendly and safe country.</p>
    <ul>
      <li>
        In 2010, Lonely Planet designated Ireland as the most friendly country
        in the world.{" "}
      </li>
      <li>
        The Global Peace Index evaluates 158 countries around the world
        according to measures such as conflict, social security and
        militarization.
      </li>
      <li>
        In 2014, Ireland was ranked as the 13th most peaceful place on the
        planet.{" "}
      </li>
      <li>
        In 2013, the OECD placed Ireland in the top 10 of the Better Life Index.
        In general, Irish people are happy with their lives and have had a more
        positive experience than the OECD average on a typical day. In addition,
        Irish people received the second highest score in the OECD for citizens
        who volunteered, donated and supported strangers. Irish have a great
        mother side and love to welcome or "mother" new guests.{" "}
      </li>
    </ul>
    <p>
      The Irish Institute of Higher Education is committed to ensuring that
      visiting students are settled in a new environment and receive all the
      information they need to have a comfortable stay. All universities have
      designated employees that international students can contact.{" "}
    </p>
    <p>
      Simply put, Irish people are a friendly and welcoming group, which is why
      international students benefit so much from their Irish experience.
    </p>
  </div>
);

const englishSpeaking = (
  <div>
    <p>
      Ireland has its own language and clear cultural identity, but English is
      the most commonly spoken language and is one of the reasons why so many
      multinationals are based here in Europe. It also makes Ireland a great
      choice for international students. .
    </p>
  </div>
);

const longTradition = (
  <div>
    <p>
      Ireland's long-standing reputation for quality education is built on a
      solid foundation of its commitment to excellence. Today, Ireland has one
      of the best educational systems in the world and has an internationally
      recognized reputation for academic quality.{" "}
    </p>
    <p>
      34 Irish institutions of higher education offer a wide range of over 5,000
      programs for obtaining high quality internationally recognized degrees.
      International students can choose to meet their educational needs in
      prestigious business schools, outstanding science and technology centers,
      prestigious languages, humanities and arts departments.
    </p>
  </div>
);

const innovativeCulture = (
  <div>
    <p>
      Did you know that the Irish are behind all of these life-changing
      inventions? Submersibles, color photographs, latest tractors, guided
      missiles, nickel-zinc batteries, portable defibrillators, short-handed
      greg systems, latest stethoscopes, rubber soles, soda water, epilepsy
      treatment, ejectors Sheets and chocolate milk!
    </p>
    <ul>
      <li>Irish Robert Boyle is considered the father of chemistry </li>
      <li>Francis Rynd invented the hypodermic syringe </li>
      <li>Charles Parsons Develops World's First Steam Turbine </li>
      <li>
        Ernest Walton, who worked with John Cockcroft, was the first person to
        artificially divide an atom.
      </li>
    </ul>
  </div>
);

export default function StudyAbroadIreland() {
  return (
    <div>
      <HeaderFive
        headerPosition="header--static logoresize"
        color="color-black"
      />
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--110 bg_image"
        data-black-overlay="1"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="container">
          <div className="col-lg-12">
            <div className="rn-page-title text-center pt--100">
              <h2 className="title " style={{ color: "white" }}>
                IRELAND
              </h2>
              <h3 style={{ color: "white" }}>
               To succeed in today's world, we need independent thinkers and practitioners, and to bring them out, we need a pioneering, creative and agile country. The country is Ireland.
              </h3>
              <p>
              Ireland is a cozy island with an international city and a strong literary history. The Irish government is working on student promotion and has invested € 11.1 billion  in education in 2020 alone. Hundreds of scholarships and funding programs are available to attract international students. 
              </p>
              <div className="about-button mt--50">
                <a
                  className="rn-button-style--2 btn-solid"
                  href="/studyAbroadWithAvenue"
                >
                  Speak with us Today
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      <LeftTextDescription
        heading="TUTION FEES FOR NON-EU/EEA STUDENTS"
        description={tutionFees}
        image="/assets/aveImages/ireland/tutionFees.jpg"
      />
      <div className="pt--110">
        <RightTextDescription
          heading="WHY STUDY IN IRELAND?"
          description={whyIreland}
          image="/assets/aveImages/ireland/whyIreland.jpg"
        />
      </div>
      <LeftTextDescription
        heading="ENGLISH-SPEAKING COUNTRIES"
        description={englishSpeaking}
        image="/assets/aveImages/ireland/englishSpeaking.jpg"
      />
      <div className="pt--110">
        <RightTextDescription
          heading="A LONG TRADITION OF EXCELLENCE IN EDUCATION"
          description={longTradition}
          image="/assets/aveImages/ireland/longTradition.jpg"
        />
      </div>
      <div className="pb--110">
        <LeftTextDescription
          heading="INNOVATIVE AND CREATIVE CULTURE"
          description={innovativeCulture}
          image="/assets/aveImages/ireland/innovationCulture.jpg"
        />
      </div>
    </div>
  );
}

import React from 'react'
import HeaderFive from '../../../component/header/HeaderFive'
import ContactForScholarship from '../../components/explore/ContactForScholarship'
import ScholarshipType from '../../components/explore/ScholarshipType'
import WeProvide from '../../components/explore/WeProvide'
import "../../css/text.css"

const Background ="/assets/aveImages/mainImage/scholarship.png";

export default function Scholarships() {
    return (
        <div>
             <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--110 bg_image "  
                 data-black-overlay="1"
                 style={{backgroundImage: `url(${Background})`,}}
                 >
                    <div className="container" >
                            <div className="col-lg-12 pt--110 text-center" >
                                <div className="rn-page-title row pt--110" >
                                    <div className="col-lg-4">
                                    <h2 className="title " style={{color:"white"}}>Scholarship</h2>
                                    </div>
                                  <div className="col-lg-8">
                                  <p className='textAlign'>With a variety of discounts and scholarship options, we at Avenue Educational Consultancy want to assist you in finding the right scholarship for studying abroad. Don't let the cost of studying abroad deter you. Simply ensure that you have the correct information and that the processing is completed on time.</p>
                                  </div>
                                </div>
                                </div>
                            </div>
                    </div>
                
                {/* End Breadcrump Area */}
                <ScholarshipType/>
                <WeProvide/>
                <ContactForScholarship/>
                
            </div>
    )
}

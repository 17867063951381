import React, { Component } from 'react';
import "../css/text.css"
import {
  FiMapPin,
  FiClock,
  } from "react-icons/fi";
  import "../css/heading.css";

const newsUpdates = [
    {
      image: "/assets/aveImages/articles/selectingBestUni.jpg",
      title: "SELECTING THE BEST UNIVERISTY",
      link:"selectingUni",
      description:
        "A better future is everyone’s dream. Whereas, education comes hand in hand with ensuring a better, brighter....",
    },
    {
      image: "/assets/aveImages/articles/5mistakes.jpg",
      title:"5 MISTAKES IN APPLICATION PROCESS TO STUDY IN CANADA",
      link:"fiveMistakes",
      description:
        "Studying in Canada is a dream for many students because it is one of the top ...",
    },
   
  ];

  const events = [
    {
      date:<h3 className="date"><span style={{color:"#3f47cc", fontSize:"xx-large"}}>20</span><br/>Feb</h3>,
      title: "IDP's Australia Education Fair",
     time:"11:00AM - 4:00PM",
     location:"Hotel Radisson, Kathmandu, Nepal",
    },
    {
        date:<h3 className="date"><span style={{color:"#3f47cc", fontSize:"xx-large"}}>22</span><br/>Jan</h3>,
        title: "IDP's Canada, Uk, and USA Education Fair",
       time:"1:00AM - 5:00PM",
       location:"Hotel Radisson, kathmandu, Nepal",
      },
  ];

  function EventCard({date,month,title,time,location}){
      return (
          <div className="content" style={{padding:"20px"}}>
          <div className="row">
              <div style={{textAlign:"right"}}>
                  {date}
                  <hr size="30px" style={{borderTop:"1px solid"}}  color="#3f47cc"/>
              </div>
              <div style={{paddingLeft:"20px"}}>
                  <h4 style={{lineHeight:0.8}}>{title}</h4>
                  <p style={{lineHeight:0.8, color:"#3f47cc"}}><FiClock /> {time}</p>
                  <p style={{lineHeight:0.8, color:"#3f47cc"}}><FiMapPin /> {location}</p>
              </div>
              </div>
          </div>
      )
  }
 
export default class AveNewsAndEvents extends Component {
    render() {
        return (
            <div>
                 {/* Start News and Updates Area  */}
        <div className="rn-featured-service-area pb--110 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              {/* Start Featured Service Area  */}
        <div className="col-lg-6 order-2 order-lg-1">
          <div className="container" >
        
            {/* Start Single Service  */}
   
              <div className="title" >
                <h2 className="news" >
                 News & Updates
                </h2>
                
              <div className="row">
                {newsUpdates.map((value, index) => (
                  <div className="col-lg-6 col-md-12 mt--30" key={index}>
                    <div className="standard-service">
                      <div className="thumbnai">
                        <img
                          src={value.image}
                          alt="Corporate Images"
                        />
                             <hr size="30px" style={{borderTop:"1px solid", marginTop:"10px"}}  color="#808080"/>
                      </div>
               
                      <div className="content">
                       
                        <h3 >
                          <a href="/service-details">{value.title}</a>
                        </h3>
                        <p className="textAlign">{value.description}</p>
                        {/* <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/about">Find out More</a>
                                    </div> */}
                        <a
                          className="btn-transparent rn-btn-dark"
                          href={value.link}
                        >
                          <span className="text" style={{color:"#3f47cc"}}>Find out More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* End Single Service  */}
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start events area */}
              <div className="col-lg-6 order-1 order-lg-2" >
               
                  <div className="title">
                      <h2 className="news">Events</h2>           
                  </div>

                  <div className="container" style={{backgroundColor:"#f3f3ff"}}>
                      {events.map((value, index)=>(
                          <EventCard 
                          date={value.date}
                          title={value.title}
                          time={value.time}
                          location={value.location}/>
                      ))}
                  </div>
                </div> 
            </div>
          </div>
        </div>
        {/* End About Area  */}       
            </div>
        )
    }
}

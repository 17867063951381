import React from 'react'
import HeaderFive from '../../../component/header/HeaderFive';
import LeftTextDescription from '../../components/LeftTextDescription'
import QuickFacts from '../../components/QuickFacts';
import RightTextDescription from '../../components/RightTextDescription'
import "../../css/text.css"

const Background ="/assets/aveImages/mainImage/canda.png";

const qualityEducation=<p> Canada's quality of education and standard of living are among the best in the world. Canada's high academic standards and strict quality control mean that you will be able to open the door to the future and receive quality education that will benefit your career in the long run.</p>

const affordableEducation=<p> Living and tuition fees for international students in Canada are generally lower than in other countries such as the United States and the United Kingdom. School attendance in Canada is affordable and globally recognized qualifications that will set you on the path to career success.</p>

const diversity=<p> As a student living in Canada, you can expect a peaceful and multicultural environment with people who are interested in learning about friendly and new cultures. With almost every ethnic group in the world participating in Canada, it's not difficult to find ethnic food and recreational activities related to a particular culture.</p>

const possibilityOfImmigrants=<p> As a student living in Canada, you can expect a peaceful and multicultural environment with people who are interested in learning about friendly and new cultures. With almost every ethnic group in the world participating in Canada, it's not difficult to find ethnic food and recreational activities related to a particular culture.</p>
 
const innovativeOpportunities=<p> Research is one of the key elements of post-secondary education in Canada. You have ample opportunity to be part of this vibrant aspect of education. In Canada, governments and industries support research in telecommunications, medicine, agriculture, computer technology, environmental science, and more.</p>
 
const health=<p> The United Nations consistently ranks Canada as one of the most livable places in the world. As an international student in Canada, you will enjoy all the same freedoms that protect Canadians, including respect for human rights, equality and a stable and peaceful society.</p>

const worldClass=<p> Canada is a bilingual country and is considered a world leader in language training. Canadian universities teach both French and English as a first and second language, which is an integral part of Canadian education. You can improve your fluency and ability in either language.</p>
const leftFacts=<ul>
 <li>Canada is the world's most educated country - more than half our residents have a college or university degree</li>
        <li>Canada has the world's longest coastline, which is bordered by three different oceans: the Atlantic, Arctic and Pacific</li>
        <li>Canada contains 10% of the world's total forest cover</li>
    </ul>
;
const rightFacts=<ul>
   <li>Canada has more lakes than any other country in the world - 563 of its lakes are larger than 100 square kilometers</li>
        <li>In 2015, a Canadian man was arrested after tying more than 100 balloons to a lawn chair and flying over the city of Calgary</li>
    </ul>;


export default function StudyAbroadCanada() {
    return (
        <div>
           <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--110 bg_image"  data-black-overlay="1"
         style={{backgroundImage: `url(${Background})`,}}
        >
           <div className="container" >
                   <div className="col-lg-12" >
                       <div className="rn-page-title text-center pt--100" >
                           <h2 className="title " style={{color:"white"}}>CANADA</h2>
                           <h3 style={{color:"white"}}>Canada accepts more than 250,000 international students, and in recent years the demand from overseas students has increased significantly.</h3>
                           <p>With affordable tuition, quality educational institutions, post-research work and immigrant options, and welcoming people, it's no surprise that Canada is rapidly becoming a major destination for international students.</p>
                           <div className="about-button mt--50">
           <a className="rn-button-style--2 btn-solid" href="/studyAbroadWithAvenue">
           Speak with us Today
           </a>
         </div>
                       </div>
                   </div>
           </div>
       </div>
       {/* End Breadcrump Area */}

       <LeftTextDescription
        heading="QUALITY EDUCATION"
        description={qualityEducation}
        image="/assets/aveImages/canada/qualityEducation.jpg"
      />
      <div className="pt--110">
      <RightTextDescription
        heading="AFFORDABLE EDUCATION"
        description={affordableEducation}
        image="/assets/aveImages/canada/affordableEdu1.jpg"
      />
      </div>
     
      <LeftTextDescription
        heading="DIVERSITY"
        description={diversity}
        image="/assets/aveImages/canada/diversity1.jpg"
      />
      <div className="pt--110">
      <RightTextDescription
        heading="POSSIBILITY OF IMMIGRANTS"
        description={possibilityOfImmigrants}
        image="/assets/aveImages/canada/possibleOfImmigrants1.jpg"
      />
   </div>
   <LeftTextDescription
        heading="INNOVATIVE AND ABUNDANT RESEARCH OPPORTUNITIES"
        description={innovativeOpportunities}
        image="/assets/aveImages/canada/researchOpportunities2.jpg"
      />
      <div className="pt--110">
      <RightTextDescription
        heading="HEALTH AND SAFE COMMUNITY"
        description={health}
        image="/assets/aveImages/canada/healthAndSafe.jpg"

      />
      </div>
      <LeftTextDescription
        heading="WORLD-CLASS FOREIGN LANGUAGE EDUCATION"
        description={worldClass}
        image="/assets/aveImages/canada/worldClassEducation1.jpg"
      />
       <QuickFacts leftFacts={leftFacts} rightFacts={rightFacts}/>
   </div>
    )
}

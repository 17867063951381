import React, { Component } from 'react'
import HeaderFive from '../../../component/header/HeaderFive';
import Accomodation from '../../components/Accomodation';
import Elicos from '../../components/Elicos';
import LeftTextDescription from '../../components/LeftTextDescription'
import QuickFacts from '../../components/QuickFacts';
import RightTextDescription from '../../components/RightTextDescription'
import "../../css/text.css"

const Background ="/assets/aveImages/mainImage/australia1.png";
const leftFacts=<ul>
    <li>8th place among the top 100 universities in the world.</li>
    <li>Out of the top 30 student cities, 5 are located in the United States.</li>
    <li>There are approximately 1,100 institutions offering 22,000 course options.</li>
    <li>In the World University Ranking System, it is ranked 9th.</li>
    </ul>;
const rightFacts=<ul>
    <li>The government invests $200 million each year.</li>
    <li>2,500,000 alumni from around the world.</li>
    <li>15 Nobel Laureates from various fields.</li>
    </ul>;

const educationSystem=(<div>
    <span className="headingStyle">SCHOOLS MANAGED BY THE GOVERNMENT</span>
<p>They are run by the government of a state or territory. The majority of Australian students do not pay significant tuition fees to attend these schools. Fees must be paid by international students and the children of international students. They cover a wide range of social and cultural issues.</p>
<span className="headingStyle">SCHOOLS THAT ARE NOT MANAGED BY THE GOVERNMENT</span>
<p>They are not owned or operated by the government of any state or territory. Tuition fees for international students are typically higher than for domestic students. They may adhere to a specific religious or cultural philosophy, which is evident in the student experience.</p>
</div>);

const vocational=(<div>
    <p>For students with a strong practical orientation, Australia's Vocational Education and Training (VET) sector provides a wide range of qualifications.</p>
<p>Students enrolled in vocational education and training (VET) are usually:</p>
<ul>
    <li>Those who have graduated from high school and are looking for work-related skills.</li>
    <li>Students who want to go to university but don't want to go to college.</li>
    <li>Skilled workers who want to advance in their careers.</li>
    <li>University graduates who need to learn how to work in a practical environment.</li>
    <li>Those who simply want to pursue their own passions.</li>
</ul>
</div>);

const university=(
<div>
    <span className="headingStyle">EDUCATION AT A UNIVERSITY</span>
<p>There are 43 universities in Australia, two of which are private (Bond and Notre Dame). Furthermore, Carnegie Mellon University and University College London both have campuses in Adelaide, South Australia. We classify these universities as non-Australian because their main campuses and administrations are located in Pennsylvania, USA, and London, UK, respectively.</p>
<p>Many Australian universities have international branch campuses, twinning agreements, and student and faculty exchange programs. Around 7000 agreements exist between Australian universities and other universities (and similar institutions around the world).</p>
<p>Universities in Australia serve three primary functions:</p>
<ul>
    <li>Knowledge storage</li>
    <li>Sharing your knowledge with others</li>
    <li>Knowledge creation</li>
    </ul>
 
</div>
);

const study=<div>
    <p>International students are charged tuition up front, which means they must pay a certain amount (not more than 50% of the total tuition fees for a course) before beginning the visa application process. Some institutions may charge laboratory fees, student union fees, library fees, and sports facility fees in addition to tuition. (ESOS Act, Division 2-Tuition fees)</p>
<span className="headingStyle">Costs are given in a reasonable manner.</span>
<ul>
    <li>Foundation Studies: $A9000–$A14,000 per year (approximately)</li>
    <li>Bachelor's Degree: $A15,000 – $A33,000 per year (approximately)</li>
    <li>Laboratory-based Bachelor's Degree: Approximately $A14,000 – $A35,000 per year.</li>
    <li>Graduate Certificates for Postgraduates Graduate diplomas pay between $A20,000 and $A37,000 per year on average.</li>
    <li>Master's and doctoral degrees pay between $A20,000 and $A37,000 per year on average.</li>
</ul>
</div>;

export default class StudyAbroadAustralia extends Component {
   
    render() {
        return (
            <div>
                 <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--110 bg_image"  data-black-overlay="1"
                  style={{backgroundImage: `url(${Background})`,}}
                 >
                    <div className="container" >
                            <div className="col-lg-12" >
                                <div className="rn-page-title text-center pt--100" >
                                    <h2 className="title " style={{color:"white"}}>AUSTRALIA</h2>
                                    <h3 style={{color:"white"}}>Australia could be the place for you if you enjoy the outdoors,<br/> a laid-back lifestyle, and adventure.</h3>
                                    <p>Get a good education in a multicultural society and have an unforgettable experience in Australia.</p>
                                    <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/studyAbroadWithAvenue">
                    Speak with us Today
                    </a>
                  </div>
                                </div>
                            </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

               <LeftTextDescription heading="EDUCATION SYSTEM"  description={educationSystem} image="/assets/aveImages/australia/australia1.png"/>
                <Elicos/>
                <RightTextDescription heading="VOCATIONAL EDUCATION AND TRAINING"  description={vocational} image="/assets/aveImages/australia/australia4.png"/>
                <LeftTextDescription heading="UNIVERSITY EDUCATION"  description={university} image="/assets/aveImages/australia/australia5.png"/>
               <QuickFacts leftFacts={leftFacts} rightFacts={rightFacts}/>
                <RightTextDescription heading="STUDY COSTS"  description={study} image="/assets/aveImages/australia/australia6.png"/>
           <Accomodation/>
            </div>
        )
    }
}

// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./avenue/css/div.css";

// Create Import File
import "./index.scss";

//avenue
import ScrollToTop from "react-scroll-up";
import Home from "./home/Home";
import AveTopBar from "./avenue/homePage/AveTopBar";
import StudyAbroadWithAvenue from "./avenue/navPages/studyAbroad/StudyAbroadWithAvenue";
import StudyAbroadAustralia from "./avenue/navPages/studyAbroad/StudyAbroadAustralia";

import Helmet from "./component/common/Helmet";
import AveFooter from "./avenue/homePage/AveFooter";
import { FiChevronUp } from "react-icons/fi";
import MessengerCustomerChat from "react-messenger-customer-chat";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Articles from "./avenue/navPages/Articles";
import Scholarships from "./avenue/navPages/explore/Scholarships";
import Universities from "./avenue/navPages/explore/Universities";
import Ielts from "./avenue/navPages/ourServices/Ielts";
import Pte from "./avenue/navPages/ourServices/Pte";
import AboutUs from "./avenue/navPages/AboutUs";
import StudyAbroadUsa from "./avenue/navPages/studyAbroad/StudyAbroadUsa";
import StudyAbroadUK from "./avenue/navPages/studyAbroad/StudyAbroadUK";
import StudyAbroadNewzealand from "./avenue/navPages/studyAbroad/StudyAbroadNewzealand";
import StudyAbroadIreland from "./avenue/navPages/studyAbroad/StudyAbroadIreland";
import StudyAbroadCanada from "./avenue/navPages/studyAbroad/StudyAbroadCanada";
import Gmat from "./avenue/navPages/ourServices/Gmat";
import Tofel from "./avenue/navPages/ourServices/Tofel";
import Sat from "./avenue/navPages/ourServices/Sat";
import SelectingUni from "./avenue/articles/SelectingUni";
import FiveMistakes from "./avenue/articles/FiveMistakes";
import MasterCourses from "./avenue/articles/MasterCourses";
import MustDos from "./avenue/articles/MustDos";
import ApplyMasterAustralia from "./avenue/articles/ApplyMasterAustralia";

const AppId = "4808505885876969";
const PageId = "100483987991359";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <div>
          <Helmet pageTitle="Avenue Consultancy" />
          <AveTopBar />
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/aboutUs`}
              component={AboutUs}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/scholarships`}
              component={Scholarships}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/universities`}
              component={Universities}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/studyAbroadWithAvenue`}
              component={StudyAbroadWithAvenue}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/studyAbroadAustralia`}
              component={StudyAbroadAustralia}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/studyAbroadCanada`}
              component={StudyAbroadCanada}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/studyAbroadIreland`}
              component={StudyAbroadIreland}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/studyAbroadNewzealand`}
              component={StudyAbroadNewzealand}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/studyAbroadUk`}
              component={StudyAbroadUK}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/studyAbroadUsa`}
              component={StudyAbroadUsa}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/articles`}
              component={Articles}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/ielts`}
              component={Ielts}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/pte`}
              component={Pte}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/gmat`}
              component={Gmat}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/sat`}
              component={Sat}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/tofel`}
              component={Tofel}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/selectingUni`}
              component={SelectingUni}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/fiveMistakes`}
              component={FiveMistakes}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/masterCourses`}
              component={MasterCourses}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/mustdos`}
              component={MustDos}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/applyMaster`}
              component={ApplyMasterAustralia}
            />
          </Switch>
          {/* Start Footer Style  */}
          <AveFooter />
          {/* End Footer Style  */}

          <MessengerCustomerChat pageId={PageId} appId={AppId} />

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}
        </div>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();

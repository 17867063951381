import React from 'react'
import HeaderFive from '../../../component/header/HeaderFive';
import ApplyNow from '../../components/ApplyNow';
import LeftTextDescription from '../../components/LeftTextDescription'
import RightTextDescription from '../../components/RightTextDescription'
import "../../css/text.css";
const Background ="/assets/aveImages/mainImage/gmat.jpg";

const intro=<div>
  <h3 className="headingStyle">What exactly is it? </h3>
<p>The Graduate Management Admission Test (GMAT) is a computer adaptive test (CAT) intended to assess certain analytical, writing, quantitative, verbal, and reading skills in written English for use in admission to a graduate management program, such as an MBA program. It requires knowledge of certain specific grammar and knowledge of certain specific algebra, geometry, and arithmetic. According to the test-owning company, the Graduate Management Admission Council (GMAC), the GMAT assesses analytical writing and problem-solving abilities while also addressing data sufficiency, logic, and critical reasoning skills that it believes to be vital to real-world business and management success. It can be taken up to five times a year but no more than eight times total.</p>
</div>

const readingComprehension=<div>
  <h3 className="headingStyle">GMAT Reading Comprehension </h3>
<p>In this section, you have to read unseen passages which are of 350 words approximately and answer multiple choice questions based on the passages. whilst taking an unbiased decision. You need not have to know any extra intricate information regarding the topic. Rather you should only be able to set your concepts right and understand the relation between various entities involved.</p>
</div>

const criticalReasoning=<div>
  <h3 className="headingStyle">GMAT Critical Reasoning </h3>
<p>In this section, a candidate has to perform an analysis, based on the given argument and mark the right answer. You will be required to extract the relevant data which would either back or oppose the given argument and select the best possible option.</p>
</div>

const sentenceCorrection= <div>
  <h3 className="headingStyle">GMAT Sentence Correction </h3>
  <p>In these questions, you will basically be provided with a sentence where a partially highlighted part needs to be assessed. As there would be multiple options provided for a right answer, which you can mark as the right answer, you need to make sure that the construction of the sentence is appropriate and that the original meaning has not been rubbed off and is not lost while correcting it. Also, you need to consider your writing skills and acknowledge the rules of English grammar.
</p>
</div>

export default function Gmat() {
    return (
        <div>
             <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--110 bg_image"  data-black-overlay="1"
         style={{backgroundImage: `url(${Background})`,}}
        >
           <div className="container" >
           <div className="col-lg-6 pt--110 " >
                       <div className="rn-page-title pt--100" >
                           <h2 className="title " style={{color:"white"}}>GMAT</h2>
                       </div>
                   </div>
           </div>
       </div>
       {/* End Breadcrump Area */}
       <div className="ptb--120">
       <RightTextDescription description={intro} image="/assets/aveImages/gmat/gmat1.png"/>
       <div className="pb--110">
       <LeftTextDescription description={readingComprehension} image="/assets/aveImages/ielts/ielts1.png"/>
       </div>
       <RightTextDescription description={criticalReasoning} image="/assets/aveImages/gmat/gmat3.png"/>
       <LeftTextDescription description={sentenceCorrection} image="/assets/aveImages/gmat/gmat2.png"/>
         <ApplyNow/>
       </div>
   </div>
    )
}

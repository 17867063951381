import React, { Component } from 'react';
import CounterOne from "../../elements/counters/CounterOne";

export default class AveCounter extends Component {
    render() {
        return (
            <div>
                {/* Start CounterUp Area */}
        <div className="rn-counterup-area pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500" style={{ lineHeight: 1.1, color:"#3f47cc" }}>
                    One of Nepal's leading educational consulting firms,
                    assisting students in achieving their goals.
                  </h3>
                  <p>
                    For academic growth, proper overseas study guidance is
                    provided. The most effective test preparation classes in the
                    city. Detailed information about the world's most
                    prestigious colleges and universities. Assistance in
                    obtaining a variety of scholarships and fellowships. Staff
                    that is focused on providing information all of the time.
                    Located in the heart of the country's major cities.
                  </p>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}
            </div>
        )
    }
}

import React from "react";
import LeftTextDescription from "../../components/LeftTextDescription";
import RightTextDescription from "../../components/RightTextDescription";
import "../../css/text.css";
import "../../css/div.css";
import HeaderFive from "../../../component/header/HeaderFive";

const Background = "/assets/aveImages/mainImage/uk.png";

const worldFamousUniversity = (
  <div>
    <p>
      {" "}
      Degrees from higher education institutions in the UK are respected around
      the world and there is a wide selection of subjects to study. UK college
      degrees look great on your resume, and getting a qualification from the UK
      is a big selling point when looking for a future job, regardless of
      industry or location.
    </p>
    <p>
      UK higher education institutions are internationally recognized for their
      creative and rewarding environment that helps keep students in the best
      possible condition. Their standards are highly valued, have experts on
      many academic topics, and are generally high in international university
      rankings.
    </p>
    <p>
      The UK higher education system has long been the foundation of higher
      education standards around the world, with innovative educational styles
      and modern equipment.
    </p>
  </div>
);

const multiCultural = (
  <div>
    <p>
      The UK is a multicultural country and it is also very popular with
      international students. In fact, the UK is the world’s second most popular
      destination for overseas students.
    </p>
    <p>
      This diversity means that our campus is full of different cultures. You
      can communicate with people from all over the world and learn more than
      just your degree.
    </p>
  </div>
);
const jobOpportunities = (
  <div>
    <p>
      Generally speaking, international students can work up to 20 hours a week
      during the semester and 10 hours after school. This allows students to
      take part-time jobs or internships, learn new skills and make money while
      learning.
    </p>
    <p>
      As part of the course, your university can even help you get an internship
      opportunity, which will definitely give you a competitive advantage when
      you graduate.
    </p>
    <p>
      The British government also announced a new post-graduation visa, allowing
      international students who graduated from the summer of 2021 to stay and
      work in the UK for another two years.
    </p>
  </div>
);
const economicBenefit = (
  <div>
    <p>
      {" "}
      International students can enjoy some financial benefits when choosing the
      UK.
    </p>
    <p>
      {" "}
      First, the time required to complete a degree in the UK is shorter than in
      other countries. In many countries, it takes four years to complete a
      graduate degree, plus two to three years. In the UK, an undergraduate
      degree takes three years and then additional time to complete a
      postgraduate qualification (unless you are a medical student or graduate
      student).
    </p>
    <p>
      International students can also receive financial assistance in the form
      of scholarships, bursaries and bursaries during their studies in the UK.
      In addition, the cost of living in the UK is reasonable, especially in
      areas outside of cities such as London.
    </p>
    <p>
      {" "}
      Research the area before applying to college to better understand prices,
      but generally speaking, entertainment, food, and rent are cheaper than in
      the United States.
    </p>
  </div>
);

const uniqueCulture = (
  <div>
    <p>
      If you decide to study in the UK, you won't get bored. Whatever your
      interests, there is always something to do. Britain is a unique country
      and is full of many families with roots from all over the world. This
      makes the UK a unique combination of different cultures, foods and
      interests. Not only can you learn about British culture, but you can also
      learn from people from other countries and cultures.
    </p>
    <p>
      Traveling to any part of the UK is intriguing because of the mix of
      restaurants, nightlife, shops and sporting activities. British people love
      to visit art galleries, bars, concerts and open markets. Therefore, you
      can find something that you can enjoy at any time outside of class hours.
    </p>
  </div>
);
const cost = (
  <div>
    <p>
      {" "}
      Most students choose to study in the UK from Nepal due to its
      affordability and high standards of education. It takes less time to
      complete your degrees from top universities in the UK than other promising
      areas of study.
    </p>
    <p>
      {" "}
      While in other countries it takes at least four years for a bachelor's
      degree and two or three years for a master's degree, in the United Kingdom
      it only takes three years for a bachelor's degree and one year for a
      postgraduate degree. This generally cuts your education costs without
      sacrificing knowledge sharing and learning quality.
    </p>
    <p>
      {" "}
      Studying in the UK from Nepal costs between £ 10,000 and £ 25,000 per year
      for part-time courses and up to £ 40,000 per year for postgraduate
      studies. The UK is one of the countries with an institutionalized national
      health care system that lowers overall health care costs.
    </p>
    <table>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Total cost to study in UK from Nepal</th>
          <th>Average fee (in Euros*)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>bachelor degree</td>
          <td>GBP 10,000 to GBP 20,000 per year</td>
        </tr>
        <tr>
          <td>2</td>
          <td>masters degree</td>
          <td>GBP 10,000 to GBP 20,000 per year</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Doctoral degree</td>
          <td>GBP 15,000 to GBP 24,000 per year</td>
        </tr>
      </tbody>
    </table>

    <table>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Living cost to study in UK from Nepal</th>
          <th>Average fee (in Euros*)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Expenses</td>
          <td>Approximate price</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Accommodation (one bed room)</td>
          <td>£400 - £600 per month</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Private Accommodation</td>
          <td>£320 - £530 per month</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Bills</td>
          <td>£40 - £50 per month</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Housekeeping / Food</td>
          <td>£160 - £200 per month</td>
        </tr>
        <tr>
          <td>6</td>
          <td>TV Licence</td>
          <td>£12.56 per month</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Mobile phone'</td>
          <td>£15 - £50 per month</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Shoes / Clothes</td>
          <td>£25 per month</td>
        </tr>
        <tr>
          <td>9</td>
          <td>Sport(average membership for the Students Sport centre)</td>
          <td>£10 - £20 per month</td>
        </tr>
        <tr>
          <td>10</td>
          <td>Stationery / Books</td>
          <td>£21 per month</td>
        </tr>
        <tr>
          <td>11</td>
          <td>Socialising</td>
          <td>£120 per month</td>
        </tr>
        <tr>
          <td>12</td>
          <td>Travel / Transport</td>
          <td>£32 per month</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default function StudyAbroadUK() {
  return (
    <div>
      <HeaderFive
        headerPosition="header--static logoresize"
        color="color-black"
      />
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--110 bg_image"
        data-black-overlay="1"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="container">
          <div className="col-lg-12">
            <div className="rn-page-title text-center pt--100">
              <h2 className="title " style={{ color: "white" }}>
                UK
              </h2>
              <h3 style={{ color: "white" }}>
                With UK education, doors will open for you wherever you go
              </h3>
              <p>
                From world recognized universities to innovative teaching
                approaches, Universities in the UK will help you reach full
                potential.
              </p>
              <div className="about-button mt--50">
                <a
                  className="rn-button-style--2 btn-solid"
                  href="/studyAbroadWithAvenue"
                >
                  Speak with us Today
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      <LeftTextDescription
        heading="WORLD FAMOUS UNIVERSITY"
        description={worldFamousUniversity}
        image="/assets/aveImages/uk/worldFamousUni.jpg"
      />
      <div className="pt--110">
        <RightTextDescription
          heading="UK IS MULTICULTURAL"
          description={multiCultural}
          image="/assets/aveImages/uk/ukMulticultural.png"
        />
      </div>

      <LeftTextDescription
        heading="BRILLIANT JOB OPPORTUNITITES"
        description={jobOpportunities}
        image="/assets/aveImages/uk/jobOpportunities.jpg"
      />
      <div className="pt--110">
        <RightTextDescription
          heading="ECONOMIC BENEFIT"
          description={economicBenefit}
          image="/assets/aveImages/uk/economicBenefit.jpg"
        />
      </div>
      <LeftTextDescription
        heading="UNIQUE CULTURE"
        description={uniqueCulture}
        image="/assets/aveImages/uk/uniqueCulture.jpg"
      />
     
        <div className="col-lg-12 ptb--110 container">
          <div className="rn-about-wrapper textAlign">
            <div style={{ paddingBottom: "30px" }}>
              <h2 style={{ textAlign: "center", fontSize: "40px" }}>
                HOW MUCH DOES IT COST TO STUDY IN THE UK FROM NEPAL?
              </h2>
            </div>
            <div className="about-inner inner">
              <div className="section-title">{cost}</div>
            </div>
          </div>
        </div>
      </div>
  );
}

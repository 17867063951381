import React, { Component } from 'react';
import "../css/text.css"


const starndardService = [
    {
      image: "/assets/aveImages/services/service1.png",
      title: "Library Services",
      description:
        "We offer a wide choice of standardized test preparation materials to our premium members alone. These services are provided by our modernized library. In addition, pamphlets, brochures, handbooks, and other publications from universities and colleges across the world are available at the library.",
    },
    {
      image: "/assets/aveImages/services/service2.png",
      title: "Scholarship Assessment Guidance",
      description:
        "We advise students who have good academic credentials and a high English proficiency test score on how much scholarship money they are eligible for at various universities and schools. Scholarships come in a variety of shapes and sizes, making it easier for students to select the finest school provider.",
    },
    {
      image: "/assets/aveImages/services/service3.png",
      title: "Pre-departure sessions",
      description:
        "The session is primarily for students who have previously obtained a visa. The seminar covers all of the essential information about how students can begin their new lives overseas, including how to take a special walk and other necessary information. Mainly with the motto to help students prepare their life in a new country.",
    },
    {
      image: "/assets/aveImages/services/service4.png",
      title: "University/College Presentations",
      description:
        "We organize presentations of different universities and colleges after getting in touch with various universities and institutions. Representatives from the individual universities/colleges are in charge of organizing this presentation. The session is excellent because it allows students to connect directly with team members.",
    },
  ];

export default class AveService2 extends Component {
    render() {
        return (
            <div>
                 {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area pb--110 bg_color--5">
          <div className="container">
           
            {/* Start Single Service  */}
            <div className="col-lg-12">
              <div>
                <h2 className="title" style={{color:"#3f47cc", textAlign:"center"}}>
                  Our Services
                </h2>
              </div>

              <div className="row">
                {starndardService.map((value, index) => (
                  <div className="col-lg-3 col-md-3 mt--30" key={index}>
                    <div className="standard-service">
                      <div className="thumbnai">
                        <img
                          src={value.image}
                          alt="service Images"
                        />
                         <hr size="30px" style={{borderTop:"1px solid", marginTop:"10px"}}  color="#808080"/>
                      </div>
                      <div className="content " >
                        <h3 style={{color:"#3f47cc"}}>
                          {value.title}
                        </h3>
                        
                        <p className="textAlign">{value.description}</p>
                      
                       
                        {/* <a
                          className="btn-transparent rn-btn-dark"
                          href="/"
                        >
                          <span className="text" style={{color:"#3f47cc"}}>Find out More</span>
                        </a> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* End Single Service  */}
          </div>
        </div>
        {/* End Featured Service Area  */}
            </div>
        )
    }
}

import React, { Component } from 'react'
import "../css/text.css";

export default class RightTextDescription extends Component {
    render() {
        return (
            <div>
                   {/* Start About Area  */}
                   <div className="rn-about-area bg_color--1">
                    <div className="rn-about-wrapper textAlign">
                    {this.props.heading && <div style={{paddingBottom:"30px"}}><h2 style={{textAlign:"center", fontSize:"40px"}}>{this.props.heading}</h2></div>}
                        <div className="container">
                            <div className="row row--35 ">
                            <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={this.props.image} alt="About Images"/>
                                    </div>
                                </div>
                            <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{this.props.title}</h2>
                                           {this.props.description}
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                
            </div>
        )
    }
}

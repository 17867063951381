import React, { Component } from 'react';
import {FaFacebookF , FaLinkedinIn, } from "react-icons/fa";
import {
    FiMail,  
    FiPhone,
    FiClock,
    FiMapPin,
  } from "react-icons/fi";

const SocialShare = [
    {Social: <FaFacebookF/> , link: 'https://www.facebook.com/Avenue-Education-Consultancy-Kathmandu-100483987991359'},
    {Social: <FaLinkedinIn/> , link: 'https://www.linkedin.com/authwall?trk=gf&trkInfo=AQH-MiRUt5tDbwAAAX2Ba-popTVsX2xfSITSmXoj1zgmntk8GA13z8KxMyXjnm0TQQZN1aHMGwYRwxgVps1tnD7OKcC6YZKPPq4mlFEuaYuZXkwo6LGZlqxfITBEaXU9rxgQsq4=&originalReferer=https://l.facebook.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F65757686%2Fadmin%2F%3Ffbclid%3DIwAR0jnpLgCMUX7WOtm7nyu0xTgpUBzUr8ofinXKtAQ-c-_2iD9HVwU__vfNU'},
]

const contentStyle={
    fontSize:"12px",
    color:"white",
}


export default class AveTopBar extends Component {
    render() {
        return (
            <div style={{backgroundColor:"#3f47cc"}}>
            <div className="container" style={{}}>
            <div className="row" style={{justifyContent:"space-between", alignItems:"center"}}>
               <div> 
               <p style={contentStyle}><FiPhone/> +977-9855053942 , +977-1-4480660</p>
                </div>
                <div>
                    <p style={contentStyle}><FiMapPin/> New Baneshwor Chowk, Kathmandu, Nepal</p>
                </div>
                <div>
                    <p style={contentStyle}><FiMail/> info@avenue.edu.np</p>
                </div>
                <div>
                    <p style={contentStyle}><FiClock/> Mon-Fri / 7:00AM - 6:00PM</p>
                </div>
                <div style={{paddingTop:"10px"}}>
                                                    <ul className="row" style={{listStyle:"none", fontSize:"12px", color:"white", alignItems:"center", justifyContent:"space-between"}}>
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`} style={{padding:"7px"}}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
            </div>
            </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import "../css/heading.css";

const boardingSchool=<div>
    <span className="headingStyle">Boarding Schools</span>
<p>Boarding is available at many private schools. Students are fully catered for while boarding, with meals, cleaning, and laundry services included in the package. Residents of the boarding house have access to the school's facilities for study and social activities. To the boarding fee range shown, tuition fees must be added.</p>
<p>Annual cost: A$8,000-A$11,000</p>
</div>

const housing=<div>
    <span className="headingStyle">Homestay and Farmstay</span>
<p>Homestay and farmstay programs place students in the home of an Australian family. In most cases, some or all meals are provided. It is a popular option for those attending high school or ELICOS courses and can be a simple way to enter Australia for young students. A homestay is a place to stay in a city, whereas a farmstay is a place to stay in the countryside. A list of suitable potential families will be available at your housing office.</p>
<p>Weekly cost: A$110-A$270</p>
</div>

const residentalCollege=<div>
    <span className="headingStyle">Residential Colleges at Universities</span>
<p>University residential colleges provide housing, as well as all meals, cleaning, and a variety of social and academic support services. Because of the extensive range of amenities available, they are generally more expensive than Halls of Residence.</p>
<p>Weekly cost: A$190-A$280
</p>
</div>

const residence = <div><span className="headingStyle">Residence Halls at Universities</span>
<p>University halls of residence also provide housing, but with a limited range of services. Some meals and cleaning are usually included, but self-catering options may be available. This type of accommodation is extremely popular, particularly among first-year students, and must be reserved well in advance of your arrival in Australia.</p>
</div>

function LeftText({leftImage, leftTitle, leftDescription}){
    return(
<div className="row row--35 pb--110">
                            <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        <h3 className="title">{leftTitle}</h3>
                                            {leftDescription}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={leftImage} alt="elicos Images"/>
                                    </div>
                                </div>    
                            </div>
    )
}

function RightText({rightImage, rightTitle, rightDescription}){
    return(
        <div className="row row--35 pb--110">
        <div className="col-lg-5">
                <div className="thumbnail">
                    <img className="w-100" src={rightImage} alt="elicos Images"/>
                </div>
            </div>
        <div className="col-lg-7">
                <div className="about-inner inner">
                    <div className="section-title">
                        <h3 className="title">{rightTitle}</h3>
                        {rightDescription}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default class Accomodation extends Component {
    render() {
        return (
            <div>
                  <div className="rn-about-area pt--110  bg_color--1">
                    <div className="rn-about-wrapper">
                    <div style={{paddingBottom:"30px"}}><h2 style={{textAlign:"center", fontSize:"40px"}}>ACCOMODATION</h2></div>
                        <div className="container textAlign">
                        <LeftText leftImage="/assets/aveImages/australia/australia7.png" leftDescription={boardingSchool} />
                        <RightText rightImage="/assets/aveImages/australia/australia8.png" rightDescription={housing}/> 
                        <LeftText leftImage="/assets/aveImages/australia/australia9.png" leftDescription={residentalCollege} />
                        <RightText rightImage="/assets/aveImages/australia/australia10.png" rightDescription={residence}/>      
                        </div>
                    </div>
                </div>
               
            </div>
        )
    }
}

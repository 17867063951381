import React, { Component } from 'react'
import "../css/text.css";

const elicos=<div>
    <span className="headingStyle">What exactly is it?</span>
<p>Australia offers a diverse range of ELICOS courses to meet the needs of students studying English for a variety of reasons. The following characteristics are common in full-time ELICOS courses:</p>
<ul>
    <li>They usually last four to 48 weeks and are taught to people of all skill levels, from beginners to advanced.</li>
    <li>They require a minimum of 20 hours of study per week.</li>
    <li>They usually have flexible start dates, allowing students to enroll at any point throughout the year.</li>
</ul>

<p>ELICOS courses are frequently taken in conjunction with formal courses in other fields. They come in a package with one or more courses. These additional courses are sometimes offered at the same institution.
</p>
</div>

const englishClassification=<div><span className="headingStyle">English classifications</span>
<p>
There are five major categories of English language courses available to international students in Australia:
</p>
<ul>
    <li>English in general.</li>
    <li>Essay Writing in English for Academic Purposes (EAP).</li>
    <li>Preparation for Secondary School</li>
    <li>Preparation for the examination.</li>
    <li>For Specific Purposes English (ESP).</li>
</ul>

<p>
International students who do not meet the language proficiency requirement by a minimum score are frequently required to begin their studies with English for Academic Purpose.</p>
</div>

export default class Elicos extends Component {
    render() {
        return (
            <div>
                 <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper textAlign">
                    <div style={{paddingBottom:"30px"}}><h2 style={{textAlign:"center", fontSize:"40px"}}>ELICOS</h2></div>
                        <div className="container">
                            <div className="row row--35  pb--110">
                            <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/aveImages/australia/australia2.png" alt="elicos Images"/>
                                    </div>
                                </div>
                            <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            {elicos}
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="row row--35 ">
                            
                            <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            {englishClassification}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/aveImages/australia/australia3.png" alt="elicos Images"/>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

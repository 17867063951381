import React, { Component } from 'react';

const ServiceListOne = [
    {
      icon: "/assets/aveImages/icons/UndergradIcon.png",
      title: "Undergrads",
    },
    {
      icon: "/assets/aveImages/icons/postgradIcon.png",
      title: "Postgrads",
    },
    {
      icon: "/assets/aveImages/icons/diplomaIcon.png",
      title: "Diploma",
    },
    {
      icon: "/assets/aveImages/icons/foundationIcon.png",
      title: "Foundation",
    },
  ];

export default class AveService1 extends Component {
    render() {
        return (
            <div>
            {/* Start Service Area */}
        <div className="service-area pb--110 bg_color--1">
        <div className="container">
          <div className="row service-one-wrapper">
            {ServiceListOne.map((val, i) => (
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12"
                key={i}
              >
                <div className="text-center">
                  <div className="service service__style--2 text-center">
                    <div className="icon"> <img
                          src={val.icon}
                          alt="icons"
                        /></div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Service Area */}
      </div>
        )
    }
}

import React from 'react'
import ArticlePage from '../components/ArticlePage'

const describe=<div>
    <p>A better future is everyone’s dream. Whereas, education comes hand in hand with ensuring a better, brighter future. And to be capable of acquiring any massive achievement, one is required to take toddler steps and select the correct direction to success. For that, appropriate coaching is needed, and that’s what Avenue Consultancy is an expert of.</p>
<p>Quality education is a compulsion to any successful career and as students are getting more conscious and educated about the modernization and fast hyper-competition in the surroundings which consist of society with the hyper-competition, students now prefer to seize every benefit they can to win this race. Globalization in this era has grown to be a compulsion to continue to exist in the contemporary market, where the thin lines between countries around the world have to be blurred. And with no exception, scholar organizations in this era prioritize having an excessive general exceptional training that presents higher attention and opportunities.</p>
    <p>It becomes tough to make that desire alone beside any expert guidance when there is so a whole lot to pick out from. The possibilities are endless. Firstly, students must make up their minds on which field they are interested in. Having even a rough vision in your mind will be such a great help in shaping your future and career. With a rough vision in mind, that makes it so much easier to decide on which country or courses or colleges, narrowing down each step as we go along. Furthermore, try researching all the courses you like. Researching is the key, if you feel like you have researched enough, then research even more. No amount of research is adequate especially when it is directly linked to your future. When you have narrowed down your courses and picked the one that interests you, research about colleges that provide those courses. Choosing colleges all comes with infrastructures, facilities, tuition fees, location, prestige, and education quality. Colleges take such a huge role in opening up to many opportunities in future careers and during education. So with the right aim set in mind and with various options, you definitely have something for yourself.</p>
<p>While choosing the right country, you must be well aware of the education system and its global relevance, if the colleges in that country’s degree are recognized or not, and the infrastructure or the economy of the country. The political state, racism, and most importantly is the crime rate for your safety. And finally climates, tuition and living expenses, most importantly is international students welfare. Where many countries have either their own protection policies for international students, and some have benefits and scholarships for international students.</p>
<p>So finding the right match and the right time will change your life and future. In the end, your every decision has its consequences so it’s important to be aware and fully prepared.</p>
</div>

export default function SelectingUni() {
    return (
        <div>
           <ArticlePage 
            image="/assets/aveImages/articles/selectingBestUni.jpg"
            title="Selecting the best University"
            description={describe}
            next1title="Top 10 Master's Courses for International Students"
            next1description="Blog"
            next1image="/assets/aveImages/articles/masterCourses.jpg"
            next1link="masterCourses"
            next2title="How to Apply for a Master's Degree in Australia?"
            next2description="Blog"
            next2image="/assets/aveImages/articles/applyMasterAustralia.jpg"
            next2link="applyMaster"
            next3title="Some must-dos before choosing a study abroad destination"
            next3description="Blog"
            next3image="/assets/aveImages/articles/mustDos.jpg"
            next3link="mustDos"
             next4title="Top 5 Mistakes in Application Proces to Study in Canada"
            next4description="Blog"
            next4image="/assets/aveImages/articles/5mistakes.jpg"
            next4link="fiveMistakes"
            />
        </div>
    )
}

import React, { Component } from "react";
import { GiBullseye, GiEyeTarget  } from "react-icons/gi";
import "../../avenue/css/text.css"

const ServiceList = [
    {
        icon: <GiBullseye />,
        title: 'OUR MISSION',
        description: 'The guiding principle at AEC is providing exam preparation and placement services that are on par with the very best in the world. In the meantime, we also aim to support the social issues by innovative means and best-in-class consulting in order to improve the quality of life and thereby creating a pool of students round the world for the social cause.'
    },
    {
        icon: <GiEyeTarget />,
        title: 'OUR VISION',
        description: 'The vision at AEC is to bring the best possible outcome for each individual who chooses to obtain our services and to contribute to society by establishing the best standards possible in all our endeavors. AEC strongly encourages every student to follow their academic dreams and believes that if one makes an honest and sustained effort to achieve one’s objectives, success is inevitable.'
    },  
]

class ServiceTwo extends Component{
    render(){
        return(
            <React.Fragment>        
                    <div className="col-lg-12 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p className="textAlign">{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;

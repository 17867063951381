import React from "react";
import HeaderFive from "../../component/header/HeaderFive";
import "../css/text.css";

const NextArticle=({description, title, buttonLink, image})=>{
  return (
    <div className="standard-service" style={{paddingBottom:"30px"}}>
          <div className="thumbnai">
            <img
              src={image}
              alt="service Images"
            />
          </div>
          <div style={{paddingTop:"20px"}} >
          <p className="textAlign">{description}</p>
            <h3 style={{color:"#3f47cc"}}>
                {title}
            </h3>

            <div className="about-button ">
                            <a className="rn-button-style--2 btn-solid" href={buttonLink}>Read More</a>
                        </div>
          </div>
        </div>
  )
}

export default function ArticlePage(props) {
    var articles = [
        {
          buttonLink:props.next1link,
          image: props.next1image,
          title: props.next1title,
          description:props.next1description,
        },
        {
          buttonLink:props.next2link,
          image: props.next2image,
          title: props.next2title,
          description:props.next2description,
        },
        {
          buttonLink:props.next3link,
          image: props.next3image,
          title: props.next3title,
          description:props.next3description,
        },
         {
          buttonLink:props.next4link,
          image: props.next4image,
          title: props.next4title,
          description:props.next4description,
        },
      ];
  return (
    <div>
       <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--110 bg_image"
        data-black-overlay="1"
        style={{ backgroundImage: `url(${props.image})` }}
      >
        <div className="container">
          <div className="col-lg-6 pt--110 ">
            <div className="rn-page-title pt--110">
              <h2 className="title " style={{ color: "white" }}>
                ARTICLES
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* End Breadcrump Area */}
      <div className="container ptb--110">
        <div className="col-lg-12 row">
          <div className="col-lg-8">
            <h3 className="titleStyle">{props.title}</h3>
            {props.description}
          </div>
          <div className="col-lg-4">
          {articles.map((value, index) => (
                  <NextArticle
                  key={index}
                image={value.image}
                  title={value.title}
                  description={value.description}
                buttonLink={value.buttonLink}
                  />
                ))}
                
          </div>
          
        </div>
      </div>
    </div>
  
  );
}

import React, { Component } from 'react'

function Courses({courseName, link}){
return (
    <div className="about-button mt--50">
                              <a
                                className="rn-button-style--2 btn-solid"
                             href={link} >
                               {courseName}
                              </a>
                            </div>
)
}

export default class AveCourses extends Component {
    render() {
        return (
            <div>
                 {/* Start Courses Area */}
        <div
          className="counterup-area ptb--120 bg_image bg_courses theme-text-white"
          data-black-overlay="1"
        >
          <div className="container"  >
              <div>
            <h2 style={{textAlign:"center", color:"white"}}>CLASSES FOR TEST PREPARATION</h2>
            </div>
            <div className="row" style={{justifyContent:"space-evenly"}}>
            <Courses courseName="IELTS" link="/ielts"/>
            <Courses courseName="PTE" link="pte"/>
            <Courses courseName="GMAT" link="gmat"/>
            <Courses courseName="TOFEL" link="tofel"/>
            <Courses courseName="SAT" link="sat"/>
               </div> 
          </div>
        </div>
        {/* End Courses Area */}
            </div>
        )
    }
}

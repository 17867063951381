import React from 'react'
import ArticlePage from '../components/ArticlePage'
const describe=<div>
    <p>One of the major decisions you make while planning to pursue your studies abroad is choosing the best destination. Sometimes your luck may just be favorable enough to get you a good deal; whereas, other times, a single wrong decision can spawn a thousand others that are entirely unnecessary. So, a few things must be carefully considered before choosing any destination for studying abroad.</p>
<h3>1. Ask yourself why you want to study abroad:</h3>
<p>You may have numerous reasons for studying abroad, such as getting a good profile for your career path, settlement plans abroad, employability and more. Hence, it is always crucial to identify the reason(s) for applying abroad that can eventually become an extremely helpful factor even when you write an SOP.</p>
<h3>2. Your paperwork must be in order:</h3>
<p>As soon as you have your passport ready, apply for your visa on time. If you are not sure about visas, open a new tab and check out your chosen country's visa requirements immediately.  At the same time, make sure that the expiration date in the passport is not any time in the near future.</p>
<h3>3. Language:</h3>
<p>For a multilingual person, it's an exciting thing to learn a new language and culture. However, for those who cannot get along, language can be a huge barrier. It's more important to check the language in which the course will be delivered, and choosing an international language or English-led institution will certainly be a wise decision. There is a possibility that certain universities have language requirements which are another thing you must not miss out during the application process, and if you think that a particular university is the best option available to you, having extra time to learn a new language before you move abroad can be beneficial.</p>
<h3>4. The Universities and the Courses Offered:</h3>
<p>When you are paying an ample amount of money from visa processing to airfare, you might want to go to a prestigious university. Choosing countries that have top-ranked universities might be helpful. It would be very beneficial to you if you are aware of the quality of higher education in a specific country after you have chosen a course offered in universities globally.</p>
<h3>5. Finances:</h3>
<p>
    If money is one of the decisive factors, look for universities that have low tuition fees. Remember other costs matters, such as accommodation, living costs, visas, travel, and much more. You can get ideas from a pretty good number of websites about the living costs of the cities and then make the decision.
</p>
<h3>6. Scholarship Options:</h3>
<p>Why pay extra money when you have the caliber to get the scholarship for your desired course? So, always look for an option where you can have a full or partial scholarship.</p>
<h3>7. Employability:</h3>
<p>Where one seeks – to acquire a good education abroad, an employment plan must be the next step. Although a good certificate or a research paper can open doors for higher opportunities, getting placement after your studies in the same country where you study will prove rather beneficial, as you will be familiar with the lifestyle and can also manage the cost of living.</p>
<h3>8. Get news and updates about your destination regularly:</h3>
<p>To have a smooth tenure in the country you choose to study in, you must get news updates on the economic condition of that country or any major political turmoil that country may be experiencing or any health crisis that might be going on in that country to be cautious.</p>
<h3>9. Proximity to home:</h3>
<p>No matter how far you have stayed away from home, you would certainly wish to meet your family members once in a while. Not being able to afford a huge amount to meet them might make you regret your own decision. If you are in a place that is completely alien to you, you will start feeling homesick sooner or later, no matter how mentally strong you are. Hence, it is recommended that you choose a destination within a reasonable traveling distance and cost.</p>
<h3>10. Necessary health check-ups:</h3>
<p>Visiting a travel clinic is another mandatory piece of advice to stay healthy overseas. To avail that facility, contact your health insurance company to let them know about your travel schedule and destination and ask for any necessary precautions. </p>
<h3>11. Have your flight details and itinerary handy:</h3>
<p>For an efficient and stress-free journey, gather all your plane tickets, maps, and itineraries, and keep them close at hand. By getting things organized quite ahead of time, you will enjoy the ride and avoid the fuss that these factors may create if not taken care of.</p>
<p>Are you able to decide now? If not, ponder over these factors.  Take help from reliable consultancies like Avenue Consultancy , who can guide you through narrowing down your choices and pick the perfect destination for you.</p>

</div>

export default function MustDos() {
    return (
        <div>
          <ArticlePage 
            image="/assets/aveImages/articles/mustDos.jpg"
            title="Some must-dos before choosing a study abroad destination"
            description={describe}
            next1title="Selecting the best University"
            next1description="Blog"
            next1image="/assets/aveImages/articles/selectingBestUni.jpg"
            next1link="selectingUni"
            next2title="How to Apply for a Master's Degree in Australia?"
            next2description="Blog"
            next2image="/assets/aveImages/articles/applyMasterAustralia.jpg"
            next2link="applyMaster"
            next3title="Top 10 Master's Courses for International Students"
            next3description="Blog"
            next3image="/assets/aveImages/articles/masterCourses.jpg"
            next3link="masterCourses"
             next4title="Top 5 Mistakes in Application Proces to Study in Canada"
            next4description="Blog"
            next4image="/assets/aveImages/articles/5mistakes.jpg"
            next4link="fiveMistakes"
            />
        </div>
    )
}

import React from "react";
import "../css/text.css";
import "../css/div.css";
import ServiceTwo from "../../elements/service/ServiceTwo";
import ApplyNow from "../components/ApplyNow";
import HeaderFive from "../../component/header/HeaderFive";

const Background = "/assets/aveImages/mainImage/aboutUs.png";

const intro = (
  <div>
    <p style={{ color: "white" }}>
      Avenue Education Consultancy Pvt. Ltd. (AEC) is an education and career
      consultancy based in Kathmandu, Nepal. We are officially authorized by the
      government of Nepal to provide Consultancy services for the benefit of
      students who desire for higher education especially in the Globe. AEC was
      established in 2014, with an industry experience of over 10 years. Run by
      the university graduates, at AEC'S students can gain valuable information
      on living life as international students, finding balance between study
      and part time work and tips ongrabbing opportunities as they move along.
    </p>
    <p style={{ color: "white" }}>
      AEC will serve as a bridge for prospective students who are in quest of a
      more challenging educational ladder by endeavoring a higher discipline to
      different colleges and/or universities in Australia, New Zealand, USA, UK,
      Ireland, Japan, Switzerland, Finland, Norway, Germany, Denmark, Poland,
      Cyprus, Singapore and some others. The knowlege and hands on training the
      international students gain abroad will add a tremendous impact in their
      respective educational background that will lead them to a higher paying
      job back home or international marketplace not to mention a big chance of
      acquiring a permanent residence when they satisfactory perform in
      theoritical and practical application.
    </p>
  </div>
);

const courses = (
  <div>
    <p style={{ textAlign: "center" }}>
      Avenue Education Consultancy Pvt. Ltd. (AEC) is one of the pioneer
      institutes of its kind in the Kathmandu, Nepal. It is a provider of
      short-term exam preparation for exams like TOEFL, IELTS, SAT, GRE and
      GMAT. AEC is a study abroad consultancy that represents institute around
      the world and place students in these institutions. AEC is a part of a
      global network that focuses on the betterment of students and helps them
      to achieve their academic objectives. We work with various partners around
      the globe in facilitating access to information for students. AEC stands
      for trust, integrity, quality and unswerving commitment to the aspirations
      of its students. It has etched its fame in the annals of education in
      Nepal by providing quality short term courses and reliable placement
      services to the Nepalese students. Avenue Education Consultancy Pvt. Ltd.
    </p>
    <p style={{ textAlign: "center" }}>
      (AEC) is the institute with the largest scope and largest wingspan in
      Nepal. Avenue is not only one of the pioneer consultancies in Nepal. It is
      also the most qualitative. It is more a mindset than an institute. It is
      this mindset of inspiring trust, possessing integrity, providing quality
      and harboring unswerving commitment that had seen in education consultancy
      industry. We are a kind of mindset that encourages proactive communication
      for the betterment of the academic world. We are also a mindset that
      advocates the adoption of the best professional standards possible and the
      highest levels of integrity.
    </p>
  </div>
);

const experience = (
  <p style={{ textAlign: "center" }}>Years of Experience: 5YEARS+</p>
);

const achievement = (
  <p style={{ textAlign: "center" }}>
    AEC is fully equipped with a professionally certified counselor team for
    student career counseling.
  </p>
);

const plans = (
  <div>
    <p>Besides the materials provided by our counterpart college/universities
    abroad we are also using the following promotional activities to attract
    prospective students:</p>
    <ul>
      <li>
        Each Saturday we conduct abroad study seminar at our venue which helps
        us to reach large segmented market area.
      </li>
      <li>
        One of the effective way to reach large numbers of potential students is
        Facebook. We have one of the largest fans in the country for educational
        consultancy:.
      </li>
      <li>
        We go through the national news paper advertisement which is one of the
        most effective tool to lure mass quantity of students and will arrange
        personal counseling.
      </li>
      <li>
        We also Conduct educational seminar and workshop for spot admission
        purposes enable the students enjoy free documentation and other
        benefits.
      </li>
      <li>
        We also distribute flyers and prospectus to make aware about our
        program.
      </li>
      <li>
        We have an interactive website to circulate all of our services, offers
        and update i.e.avenueeducaton.edu.np
      </li>
      <li>
        We also get students from words of mouth of our previous applicants who
        went to study abroad plus of course the help of educational agents
      </li>
      <li>
        We also visit different colleges and take abroad study classes as one
        way to promote Our educational programs.
      </li>
    </ul>
    <p>Basically we use these promotional strategy based on the current market
    needs and we believe on student follow up system.
  </p>
  </div>
);

const purpose = (
  <p>
    True to our vision, we aim at remaining synonymous with any force that looks
    at accessing knowledges through diversity, optimizing study option to suit
    every student’s need and setting a benchmark for quality control, delivery
    and corporate social responsibility in the education industry.
    <br />
    <br />
    <span className="purposeHeading">
      Be a trustworthy guru with relevant & up-to-date information
    </span>
    <br />
    <span>
      The Avenue is committed to giving students all relevant accurate
      information about life, education, accommodation & jobs. After learning
      students’ preferred area of study, we give them every minute detail about
      the institution they wish to attend. Stop wasting time, visit us and learn
      your potential defy all obstacles and walk confidently into your future.
    </span>
    <br />
    <br />
    <span className="purposeHeading">
      Be a nice place to get enrolled for various test preparation and language
      classes
    </span>
    <br />
    <span>
      Apart from counseling, we have various test preparation classes such as
      TOEFL, IELTS, SAT, GRE and GMAT run by experienced, friendly, and dynamic
      tutors whom you are going to miss in the land of your dreams. We have
      various language classes and modern facilities.
    </span>
    <br />
    <br />
    <span className="purposeHeading">
      Your threshold to the international Universities/Colleges
    </span>
    <br />
    <span>
      The choice of the university/ college and course will have a decisive
      influence on your future career. So, it’s obvious that you must choose the
      right study path. We undoubtedly, help in providing a better path, through
      counseling and work as a conduit in your search for ideal colleges and
      universities abroad. Guiding Nepalese students in every aspect of study
      abroad, we follow our own instincts to provide the best educational
      institute abroad. Since the educational institutions are not easily
      accessible for every student. The Avenue with its professionals and
      experts is always ready to help aspirants in the university/college of
      their preference. Supporting students in every aspect, we help them
      prepare for the future. We provide minute details on each and every facet
      of student life abroad. So, we request the students interested in abroad
      study to visit our premises once, to feel the difference, how we can help
      you to ensure your future and career.
    </span>
    <br />
    <br />
    <span className="purposeHeading">
      Be the organization with clear intention to keep aspirants up-to-date with
      information through fairs/seminars
    </span>
    <br />
    <span>
      Above and beyond the objectives, our basic aim is to organize seminars and
      workshops. The purpose of such programmes is to make students well
      acquainted with foreign universities/colleges and the courses offered, so
      that they can pick the best one in which to pursue higher education. The
      Avenue believes that such seminars/workshops would be the best help in
      planning the future.
    </span>
    <br />
    <br />
  </p>
);

export default function AboutUs() {
  return (
    <div>
       <HeaderFive
            headerPosition="header--static logoresize"
            color="color-black"
          />
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--110 bg_image"
        data-black-overlay="1"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="container">
          <div className="col-lg-6 pt--110 ">
            <div className="rn-page-title  pt--100">
              <h2 className="title " style={{ color: "white" }}>
                About Us
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}
      <div className="container pt--110 pb--110">
        <div className="aboutMain">
          <h3 className="mainHeading">AVENUE EDUCATION CONSULTANCY</h3>
          {intro}
        </div>
        <div className="aboutCourses">
          <h3 className="aboutTitle">TOEFL, IELTS, SAT, GRE and GMAT</h3>
          {courses}
        </div>
        <div className="col-lg-12 col-12 mt_md--50 aboutDescription">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <h3 className="aboutTitle">OUR EXPERIENCE</h3>
              {experience}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <h3 className="aboutTitle">OUR ACHIEVEMENTS</h3>
              {achievement}
            </div>
          </div>
        </div>

        <div className="aboutDescription">
          <h3 className="headingStyle">USUAL MARKETING PLAN</h3>
          {plans}
        </div>
        <div className="aboutDescription">
          <h3 className="headingStyle">PURPOSE</h3>
          {purpose}
        </div>
        <div>
          <ServiceTwo />
        </div>
        <ApplyNow />
      </div>
    </div>
  );
}

import React, { Component } from 'react';
import "../css/text.css"

const feedbacks = [
  {
  image:"/assets/aveImages/customerImages/customer1.jpg",
    message:"A reputed brand like Avenue helped me to achieve my dream to study abroad. I am really thankful to whole team.",
    name:"Mina Karki",
    stars:"⭐⭐⭐⭐⭐"
  },
  {
    image:"/assets/aveImages/customerImages/customer2.jpg",
    message:"Not only name but actually consultant role that avenue played for my career development is undescribable in words.",
    name:"Nisha Thapa",
    stars:"⭐⭐⭐⭐⭐"
  },
  {
    image:"/assets/aveImages/customerImages/customer3.jpg",
    message:"Instead of focusing on the competition, avenue focused on students like me to achieve our dreams and make our future bright.",
    name:"Roman Magar",
    stars:"⭐⭐⭐⭐⭐"
  },
]

function FeedbackCard(){
  return (
    <>
         <div className="service-area  bg_color--1">
        <div className="container">
          <div className="row service-one-wrapper">
            {feedbacks.map((val, i) => (
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                key={i}
              >
                <a className="text-center" href="/service-details">
                  <div className="service service__style--2" style={{padding:"20px 20px 20px 20px"}}>
                    <div className="row">
                    <div className="col-lg-3 align-item-center" >
                        <img
                          src={val.image}
                          alt="Customers Images"
                          style={{borderRadius:"50%"}}
                        />
                        </div>
                    <div className="content col-lg-9" style={{textAlign:"left"}}>
                      <p>{val.message}</p>
                      <p>{val.stars}</p>
                    </div>
                    </div> 
                    <span className=" customerName"   >{val.name}</span>
                  </div>
                  
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default class CustomerFeedback extends Component {
    render() {
        return (
            <div>
                       {/* Start feedback Area  */}
        <div className="rn-team-area pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title" style={{color:"#3f47cc"}}>What our Customers are saying?</h2>
                </div>
              </div>
            </div>
            <div>     
                <FeedbackCard/>
            </div>
          </div>
        </div>
        {/* End feedback Area  */} 
            </div>
        )
    }
}

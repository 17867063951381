import React, { Component } from 'react';

const Universities = [
    {
        images: '/assets/aveImages/countries/aus.png',
        category: ' AUSTRALIA',
        link:'/studyAbroadAustralia'
    },
    {
        images: '/assets/aveImages/countries/newz.png',
        category: 'NEWZEALAND',
        link:'/studyAbroadNewzealand'
    },

    {
        images: '/assets/aveImages/countries/usa.png',
        category: 'USA',
        link:'/studyAbroadUsa'

    },
    {
        images: '/assets/aveImages/countries/japan.png',
        category: 'UK',
        link:'/studyAbroadUk'
    },
]

 

export default class AveDestination extends Component {
    render() {
        return (
            <div>
                       {/* Start Blog Area */}
        <div className="rn-blog-area pb--110 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center">
                  <h2 className="title" style={{color:"#3f47cc"}}>Which university do you want to attend?</h2>
                  <p className="description">
                  We recommend the best college in the best location for you to further your career.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60">
              {Universities.map((value, i) => (
                <div className="col-lg-3 col-md-3 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <a href={value.link}>
                    <div className="thumbnail">
                 
                      <img
                          className="w-100"
                           src={value.images}
                          alt="Blog Images"
                        />
                       
                   
                    </div>
                    <div className="content" style={{textAlign:"center"}}>
                     
                    <h3 className="title" style={{color:"white"}}>
                        {value.category}
                      </h3>
                     
                      <h4 className="title">
                       u
                      </h4>
                    </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Blog Area */}
            </div>
        )
    }
}
